import { useNavigate } from "react-router-dom";
import React from "react";
import { BusinessService, OpenAPI } from "../../tapi";

function RegisterBusinessPage() {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = React.useState("");
  const [error, setError] = React.useState("");

  function registerSellerHandler(event: any) {
    event.preventDefault();
    console.log(companyName);
    registerSeller(companyName)
      .then((resp) => {
        console.log(resp);
        setError("");
        navigate("/business");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function registerSeller(companyName: string) {
    OpenAPI.BASE = "https://tcore.azurewebsites.net";
    OpenAPI.HEADERS = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    return BusinessService.postBusinessCreate({
      name: companyName,
    });
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Otvoriť účet pre predajcu
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={registerSellerHandler}
          >
            <div>
              <label
                htmlFor="companyName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Názov firmy
              </label>
              <div className="mt-2">
                <input
                  onChange={(event) => setCompanyName(event.target.value)}
                  id="companyName"
                  name="companyName"
                  type="text"
                  autoComplete="companyName"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={companyName}
                />
              </div>
            </div>

            {error !== "" && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">
                  Otvorenie obchodu sa nepodarilo!
                </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Začni predávať
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Otvorenie obchodného účtu je potrebné pre predajcov. Ak ste
            zákazník, nemusíte sa registrovať ako predajca. Predajca bude môcť
            spravovať svôj obchod a ponúkať svoje produkty. Otvorenie účtu je
            bezplatné. Každý predajca musí mať platné IČO a jeho obchod bude
            podliehať našim podmienkam používania.
          </p>
        </div>
      </div>
    </>
  );
}

export default RegisterBusinessPage;
