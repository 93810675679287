import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import classes from "./ManageStockPage.module.css";
// import formatDate from "../../helpers/DateUtils";
import { Commodity, StockDTO } from "../../tapi";
import ApiClient from "../../helpers/ApiClient";
import CommodityOption from "../../components/CommodityOption";

const apiClient = new ApiClient();

function ManageStockPage() {
  const { businessId } = useParams<{ businessId: string }>();
  const [store, setStore] = React.useState([] as StockDTO[]);
  const [commodities, setCommodities] = React.useState([] as Commodity[]);

  useEffect(() => {
    if (businessId === undefined) {
      console.log("businessId is undefined");
      return;
    }
    apiClient.getStockOfBusiness(businessId).then(
      (response: StockDTO[]) => {
        setStore(response);
      },
      (error) => {
        console.log(error);
      },
    );
    apiClient.getCommodities().then(
      (response) => {
        setCommodities(response);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [businessId]);

  function DeleteStockHandler(event: any) {
    event.preventDefault();
    const uuid = event.target.getAttribute("data-uuid");
    if (uuid) {
      apiClient.deleteStock(uuid).then(
        (_response) => {
          window.location.reload();
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }

  return (
    <div className="mx-auto max-w-6xl">
      <div className="text-center mb-20">
        <h1 className="text-4xl tracking-tight text-gray-900 sm:text-4xl">
          Skladový manažment
        </h1>
      </div>

      <div className="grid gap-4 grid-cols-1">
        <Link to={"/business/" + businessId + "/stock/add"}>
          <div
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.addStock}`}
          >
            <h2 className="text-gray-800 text-2xl">+</h2>
            <p className="mt-2 text-gray-600">Pridaj novú položku do skladu</p>
          </div>
        </Link>

        {store.map((stock) => {
          return (
            <div
              key={stock.id}
              className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.StockBox}`}
              style={{ position: "relative" }}
            >
              <div className="text-sm font-semibold leading-6 text-gray-900">
                Komodita:{" "}
                {commodities.filter((x) => x.id === stock.commodityId)[0] !==
                  undefined && (
                  <CommodityOption
                    commodity={
                      commodities.filter((x) => x.id === stock.commodityId)[0]
                    }
                  />
                )}
              </div>
              <div className="text-sm text-gray-600">
                Názov: {stock.commodity?.name}
                <br />
                Popis: {stock.description}
              </div>
              <div className="text-sm text-gray-600">
                Naše id: {stock.id ? stock.id : "null"}
                <br />
                Vaše id: {stock.externalId ? stock.externalId : "null"}
                <br />
                Id komodity: {stock.commodityId ? stock.commodityId : "null"}
                <br />
                Množstvo: {stock.quantity ? stock.quantity : "null"}
                <br />
                Inkrement množstva:{" "}
                {stock.quantityIncrement ? stock.quantityIncrement : "null"}
                <br />
                Množstvo k dispozícii:{" "}
                {stock.quantityAvailable ? stock.quantityAvailable : "null"}
                <br />
                Jednotka:{" "}
                {stock.commodity?.unitId ? stock.commodity?.unitId : "null"}
                <br />
                Jednotková cena:{" "}
                {stock.pricePerUnitExVat ? stock.pricePerUnitExVat : "null"}
                <br />
                Archivované: {stock.archived ? "Áno" : "Nie"}
                <br />
                Id adresy:{" "}
                {stock.business?.defaultAddressId
                  ? stock.business?.defaultAddressId
                  : "null"}
                <br />
                Id businesu: {stock.businessId ? stock.businessId : "null"}
                <br />
                Obrázok: {stock.image ? stock.image : "null"}
                <br />
              </div>

              <div
                className="text-sm text-gray-600 mt-8 text-center"
                style={{ position: "absolute", bottom: "10px" }}
              >
                <Link
                  to={
                    "/business/" + businessId + "/stock/" + stock.id + "/edit"
                  }
                  className="text-blue-800 hover:underline"
                >
                  Upraviť
                </Link>
                {"  |  "}
                <Link
                  to=""
                  onClick={DeleteStockHandler}
                  data-uuid={stock.id}
                  className="text-blue-800 hover:underline"
                >
                  Zmazať
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ManageStockPage;
