/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from "../models/Address";
import type { AddressResponse } from "../models/AddressResponse";
import type { Basket } from "../models/Basket";
import type { BooleanOk } from "../models/BooleanOk";
import type { Business } from "../models/Business";
import type { MasterOrder } from "../models/MasterOrder";
import type { Ok } from "../models/Ok";
import type { OkResult } from "../models/OkResult";
import type { Role } from "../models/Role";
import type { UpdateEmailRequest } from "../models/UpdateEmailRequest";
import type { UpdateFullNameRequest } from "../models/UpdateFullNameRequest";
import type { UpdatePasswordRequest } from "../models/UpdatePasswordRequest";
import type { UpdatePhoneNumberRequest } from "../models/UpdatePhoneNumberRequest";
import type { UpdateTwoFactorAuthenticationRequest } from "../models/UpdateTwoFactorAuthenticationRequest";
import type { User } from "../models/User";
import type { UserDashboard } from "../models/UserDashboard";
import type { UserResponse } from "../models/UserResponse";
import type { UserRole } from "../models/UserRole";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UsersService {
  /**
   * Get the authenticated user profile.
   * @returns UserResponse Success
   * @throws ApiError
   */
  public static getUsersMe(): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Removes the authenticated user's account.
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteUsersMe(): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Users/Me",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get the authenticated users basket.
   * @returns Basket Success
   * @throws ApiError
   */
  public static getUsersMeBasket(): CancelablePromise<Array<Basket>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/Basket",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get the authenticated users orders.
   * @returns MasterOrder Success
   * @throws ApiError
   */
  public static getUsersMeOrders(): CancelablePromise<Array<MasterOrder>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/Orders",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get the authenticated user's roles.
   * @returns string Success
   * @throws ApiError
   */
  public static getUsersMeRoles(): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/Roles",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get a list of businesses of the authenticated user.
   * @returns Business Success
   * @throws ApiError
   */
  public static getUsersMeBusiness(): CancelablePromise<Array<Business>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/Business",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get the main dashboard of the authenticated user
   * @returns UserDashboard Success
   * @throws ApiError
   */
  public static getUsersMeMyDashboard(): CancelablePromise<UserDashboard> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/MyDashboard",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get addresses for the authenticated user.
   * @returns AddressResponse Success
   * @throws ApiError
   */
  public static getUsersMeAddresses(): CancelablePromise<
    Array<AddressResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/Addresses",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get the default address for the authenticated user.
   * @returns Address Success
   * @throws ApiError
   */
  public static getUsersMeAddressesDefault(): CancelablePromise<Address> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/Addresses/Default",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Set the default Address for the authenticated user.
   * @param addressId
   * @returns OkResult Success
   * @throws ApiError
   */
  public static patchUsersMeAddressesDefault(
    addressId: string,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/Users/Me/Addresses/{addressId}/Default",
      path: {
        addressId: addressId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete my address.
   * @param addressId
   * @returns OkResult Success
   * @throws ApiError
   */
  public static deleteUsersMeAddresses(
    addressId: string,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Users/Me/Addresses/{addressId}",
      path: {
        addressId: addressId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Request phone verification code for the authenticated user's.
   * @returns void
   * @throws ApiError
   */
  public static getUsersMeVerifyPhone(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/VerifyPhone",
      errors: {
        401: `Unauthorized`,
        503: `Server Error`,
      },
    });
  }

  /**
   * Confirm phone number with verification code of the authenticated user's.
   * @param verificationCode
   * @returns any Success
   * @throws ApiError
   */
  public static getUsersMeConfirmPhone(
    verificationCode?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Me/ConfirmPhone",
      query: {
        verificationCode: verificationCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Change the authenticated user's password.
   * @param requestBody
   * @returns Ok Success
   * @throws ApiError
   */
  public static postUsersMeUpdatePassword(
    requestBody?: UpdatePasswordRequest,
  ): CancelablePromise<Ok> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Me/UpdatePassword",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Not yet.
   * @param requestBody
   * @returns OkResult Success
   * @throws ApiError
   */
  public static postUsersMeResetPassword(
    requestBody?: UpdatePasswordRequest,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Me/ResetPassword",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Change the authenticated user's full name.
   * @param requestBody
   * @returns BooleanOk Success
   * @throws ApiError
   */
  public static postUsersMeUpdateFullName(
    requestBody?: UpdateFullNameRequest,
  ): CancelablePromise<BooleanOk> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Me/UpdateFullName",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Change the authenticated user's email.
   * @param requestBody
   * @returns BooleanOk Success
   * @throws ApiError
   */
  public static postUsersMeUpdateEmail(
    requestBody?: UpdateEmailRequest,
  ): CancelablePromise<BooleanOk> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Me/UpdateEmail",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Change the authenticated user's phone number.
   * @param requestBody
   * @returns BooleanOk Success
   * @throws ApiError
   */
  public static postUsersMeUpdatePhoneNumber(
    requestBody?: UpdatePhoneNumberRequest,
  ): CancelablePromise<BooleanOk> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Me/UpdatePhoneNumber",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Change the authenticated user's two factor authentication status.
   * @param requestBody
   * @returns BooleanOk Success
   * @throws ApiError
   */
  public static postUsersMeUpdateTwoFactorAuthentication(
    requestBody?: UpdateTwoFactorAuthenticationRequest,
  ): CancelablePromise<BooleanOk> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Me/UpdateTwoFactorAuthentication",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * [DEVELOPER] Get a user using url parameters.
   * @param id
   * @param email
   * @param phoneNumber
   * @returns UserResponse Success
   * @throws ApiError
   */
  public static getUsers(
    id?: string,
    email?: string,
    phoneNumber?: string,
  ): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users",
      query: {
        Id: id,
        Email: email,
        PhoneNumber: phoneNumber,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Update user's information by url parameters.
   * @param requestBody
   * @returns OkResult Success
   * @throws ApiError
   */
  public static putUsers(requestBody?: User): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/Users",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * [DEVELOPER] Remove user's account by url parameters.
   * @param id
   * @param email
   * @param phoneNumber
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteUsers(
    id?: string,
    email?: string,
    phoneNumber?: string,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Users",
      query: {
        Id: id,
        Email: email,
        PhoneNumber: phoneNumber,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Get a user by ID.
   * @param userId
   * @returns UserResponse Success
   * @throws ApiError
   */
  public static getUsers1(userId: string): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/{userId}",
      path: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Remove user's account by ID.
   * @param userId
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteUsers1(userId: string): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Users/{userId}",
      path: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Get the users master orders.
   * @param userId
   * @returns MasterOrder Success
   * @throws ApiError
   */
  public static getUsersOrders(
    userId: string,
  ): CancelablePromise<Array<MasterOrder>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/{userId}/Orders",
      path: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Create a role.
   * @param requestBody
   * @returns OkResult Success
   * @throws ApiError
   */
  public static postUsersRoles(
    requestBody?: Role,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/Roles",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * [DEVELOPER] Get user's roles by url parameters.
   * @param id
   * @param email
   * @param phoneNumber
   * @returns string Success
   * @throws ApiError
   */
  public static getUsersRoles(
    id?: string,
    email?: string,
    phoneNumber?: string,
  ): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/Roles",
      query: {
        Id: id,
        Email: email,
        PhoneNumber: phoneNumber,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Get user's roles by ID.
   * @param userId
   * @returns string Success
   * @throws ApiError
   */
  public static getUsersRoles1(
    userId: string,
  ): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Users/{userId}/Roles",
      path: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Adds a user into a role.
   * @param userId
   * @param requestBody
   * @returns boolean Success
   * @throws ApiError
   */
  public static postUsersRoles1(
    userId: string,
    requestBody?: UserRole,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Users/{userId}/Roles",
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Remove a user from a role.
   * @param userId
   * @param requestBody
   * @returns boolean Success
   * @throws ApiError
   */
  public static deleteUsersRoles(
    userId: string,
    requestBody?: UserRole,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Users/{userId}/Roles",
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
