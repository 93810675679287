import { useEffect, useState } from "react";
// import { StarIcon } from '@heroicons/react/20/solid'
// import { RadioGroup } from '@headlessui/react'
import { Link, useParams } from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";
import { StockDTO } from "../../tapi";

// const produkt = {
//     name: 'Fajnové uhlie',
//     price: '42 Eur',
//     href: '#',
//     breadcrumbs: [
//         { id: 1, name: 'Komodity', href: '/komodity' },
//         { id: 2, name: 'Uhlie', href: '/komodity/65af1669-da15-42ae-0c7a-08db92ccca72' },
//     ],
//     images: [
//         {
//             src: '/images/uhlie1.jpg',
//             alt: 'Dobré uhlie.',
//         },
//         {
//             src: '/images/uhlie2.jpg',
//             alt: 'Máme skladom',
//         },
//         {
//             src: '/images/uhlie3.jpg',
//             alt: 'Horí fajne',
//         },
//         {
//             src: '/images/uhlie4.jpg',
//             alt: 'Ešte aj tu je kúsok uhlia',
//         },
//     ],
//     colors: [
//         { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
//         { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
//         { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
//     ],
//     sizes: [
//         { name: 'Hnedé ', inStock: false },
//         { name: 'Brikety Union 3', inStock: true },
//         { name: 'Brikety Record 6', inStock: true },
//         { name: 'Čierne, koks', inStock: true },
//         { name: 'Eko Hrášok', inStock: false },
//     ],
//     description:
//         'Brikety REKORD 4  /veľkosť 4/ výhrevnosť: 19,6  MJ; voľne ložené',
//     highlights: [
//         'Ceny platné dokiaľ je skladom.',
//         'Ceny sú uvedené vrátane DPH.',
//         'Uhlie Vám zabalíme do 25 kg vriec na objednávku do cca  5 dní, cena je cca o 40 eur vyššia na tone uhlia',
//         'spotrebná daň z uhlia pre právnické osoby',
//     ],
//     details:
//         'Čierne uhlie a eko hrášok nie je na sklade a nebude do konca roka.',
// }
// const reviews = { href: '#', average: 4, totalCount: 117 }

// function classNames(...classes: string[]) {
//     return classes.filter(Boolean).join(' ')
// }

const apiClient = new ApiClient();

export default function ProductDetailPage() {
  // const [selectedSize, setSelectedSize] = useState(produkt.sizes[2])
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState({} as StockDTO);

  useEffect(() => {
    apiClient.getStockByStockId(String(productId)).then(
      (product: StockDTO) => {
        setProduct(product);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [productId]);

  return (
    <div
      className="mx-auto w-full max-w-container
                  bg-white mt-6 px-6 py-24 rounded-md sm:py-32
                  scroll-m-20 sm:scroll-m-24 xl:scroll-m-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <nav aria-label="Breadcrumb">
            <ol className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              {/*{produkt.breadcrumbs.map((breadcrumb) => (*/}
              {/*    <li key={breadcrumb.id}>*/}
              {/*        <div className="flex items-center">*/}
              {/*            <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">*/}
              {/*                {breadcrumb.name}*/}
              {/*            </a>*/}
              {/*            <svg*/}
              {/*                width={16}*/}
              {/*                height={20}*/}
              {/*                viewBox="0 0 16 20"*/}
              {/*                fill="currentColor"*/}
              {/*                aria-hidden="true"*/}
              {/*                className="h-5 w-4 text-gray-300"*/}
              {/*            >*/}
              {/*                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />*/}
              {/*            </svg>*/}
              {/*        </div>*/}
              {/*    </li>*/}
              {/*))}*/}
              <li className="text-sm">
                <Link
                  to={"/product/" + product.id}
                  aria-current="page"
                  className="font-medium text-gray-500 hover:text-gray-600"
                >
                  {product.commodity?.name}
                </Link>
              </li>
            </ol>
          </nav>

          {/* Image gallery */}
          {/*<div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">*/}
          {/*    <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">*/}
          {/*        <img*/}
          {/*            src={produkt.images[0].src}*/}
          {/*            alt={produkt.images[0].alt}*/}
          {/*            className="h-full w-full object-cover object-center"*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*    <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">*/}
          {/*        <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">*/}
          {/*            <img*/}
          {/*                src={produkt.images[1].src}*/}
          {/*                alt={produkt.images[1].alt}*/}
          {/*                className="h-full w-full object-cover object-center"*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*        <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">*/}
          {/*            <img*/}
          {/*                src={produkt.images[2].src}*/}
          {/*                alt={produkt.images[2].alt}*/}
          {/*                className="h-full w-full object-cover object-center"*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*    <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">*/}
          {/*        <img*/}
          {/*            src={produkt.images[3].src}*/}
          {/*            alt={produkt.images[3].alt}*/}
          {/*            className="h-full w-full object-cover object-center"*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/* Product info */}
          <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
            <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {product.commodity?.name}
              </h1>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">
                Meno: {product.commodity?.name}
              </p>
              <p className="text-3xl tracking-tight text-gray-900">
                Popis: {product.description}
              </p>
              <p className="text-3xl tracking-tight text-gray-900">
                Mnozstvo: {product.quantity}
              </p>
              <p className="text-3xl tracking-tight text-gray-900">
                Jednotka: {product.commodity?.name}
              </p>
              <p className="text-3xl tracking-tight text-gray-900">
                Business: {product.business?.name}
              </p>
              {/* Reviews */}
              {/*<div className="mt-6">*/}
              {/*    <h3 className="sr-only">Reviews</h3>*/}
              {/*    <div className="flex items-center">*/}
              {/*        <div className="flex items-center">*/}
              {/*            {[0, 1, 2, 3, 4].map((rating) => (*/}
              {/*                <StarIcon*/}
              {/*                    key={rating}*/}
              {/*                    className={classNames(*/}
              {/*                        reviews.average > rating ? 'text-gray-900' : 'text-gray-200',*/}
              {/*                        'h-5 w-5 flex-shrink-0'*/}
              {/*                    )}*/}
              {/*                    aria-hidden="true"*/}
              {/*                />*/}
              {/*            ))}*/}
              {/*        </div>*/}
              {/*        <p className="sr-only">{reviews.average} z 5 hviezdičiek</p>*/}
              {/*        <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">*/}
              {/*            {reviews.totalCount} hodnotení*/}
              {/*        </a>*/}
              {/*    </div>*/}
              {/*</div>*/}

              {/*<form className="mt-10">*/}
              {/*    /!* Colors *!/*/}
              {/*    <div>*/}
              {/*        <h3 className="text-sm font-medium text-gray-900">Dodávateľ</h3>*/}
              {/*        <p>Doležal a Syn sro, Praha</p>*/}
              {/*    </div>*/}

              {/*    /!* Sizes *!/*/}
              {/*    <div className="mt-10">*/}
              {/*        <div className="flex items-center justify-between">*/}
              {/*            <h3 className="text-sm font-medium text-gray-900">Size</h3>*/}
              {/*            <Link to="/otazky" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">*/}
              {/*                Typy uhľia*/}
              {/*            </Link>*/}
              {/*        </div>*/}

              {/*        <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">*/}
              {/*            <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>*/}
              {/*            <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">*/}
              {/*                {produkt.sizes.map((size) => (*/}
              {/*                    <RadioGroup.Option*/}
              {/*                        key={size.name}*/}
              {/*                        value={size}*/}
              {/*                        disabled={!size.inStock}*/}
              {/*                        className={({ active }) =>*/}
              {/*                            classNames(*/}
              {/*                                size.inStock*/}
              {/*                                    ? 'cursor-pointer bg-white text-gray-900 shadow-sm'*/}
              {/*                                    : 'cursor-not-allowed bg-gray-50 text-gray-200',*/}
              {/*                                active ? 'ring-2 ring-indigo-500' : '',*/}
              {/*                                'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'*/}
              {/*                            )*/}
              {/*                        }*/}
              {/*                    >*/}
              {/*                        {({ active, checked }) => (*/}
              {/*                            <>*/}
              {/*                                <RadioGroup.Label as="span">{size.name}</RadioGroup.Label>*/}
              {/*                                {size.inStock ? (*/}
              {/*                                    <span*/}
              {/*                                        className={classNames(*/}
              {/*                                            active ? 'border' : 'border-2',*/}
              {/*                                            checked ? 'border-indigo-500' : 'border-transparent',*/}
              {/*                                            'pointer-events-none absolute -inset-px rounded-md'*/}
              {/*                                        )}*/}
              {/*                                        aria-hidden="true"*/}
              {/*                                    />*/}
              {/*                                ) : (*/}
              {/*                                    <span*/}
              {/*                                        aria-hidden="true"*/}
              {/*                                        className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"*/}
              {/*                                    >*/}
              {/*        <svg*/}
              {/*            className="absolute inset-0 h-full w-full stroke-2 text-gray-200"*/}
              {/*            viewBox="0 0 100 100"*/}
              {/*            preserveAspectRatio="none"*/}
              {/*            stroke="currentColor"*/}
              {/*        >*/}
              {/*          <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />*/}
              {/*        </svg>*/}
              {/*      </span>*/}
              {/*                                )}*/}
              {/*                            </>*/}
              {/*                        )}*/}
              {/*                    </RadioGroup.Option>*/}
              {/*                ))}*/}
              {/*            </div>*/}
              {/*        </RadioGroup>*/}
              {/*    </div>*/}

              {/*    <div>*/}
              {/*        <h3 className="mt-10 text-sm font-medium text-gray-900">Množstvo</h3>*/}
              {/*        <p>100kg</p>*/}
              {/*    </div>*/}

              {/*    <button*/}
              {/*        type="submit"*/}
              {/*        className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
              {/*    >*/}
              {/*        Pridaj do objednávky*/}
              {/*    </button>*/}
              {/*</form>*/}
            </div>

            <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
              {/* Description and details */}
              {/*<div>*/}
              {/*    <h3 className="sr-only">Popis</h3>*/}

              {/*    <div className="space-y-6">*/}
              {/*        <p className="text-base text-gray-900">{produkt.description}</p>*/}
              {/*    </div>*/}
              {/*</div>*/}

              {/*<div className="mt-10">*/}
              {/*    <h3 className="text-sm font-medium text-gray-900">Hlavné body</h3>*/}

              {/*    <div className="mt-4">*/}
              {/*        <ul className="list-disc space-y-2 pl-4 text-sm">*/}
              {/*            {produkt.highlights.map((highlight) => (*/}
              {/*                <li key={highlight} className="text-gray-400">*/}
              {/*                    <span className="text-gray-600">{highlight}</span>*/}
              {/*                </li>*/}
              {/*            ))}*/}
              {/*        </ul>*/}
              {/*    </div>*/}
              {/*</div>*/}

              {/*<div className="mt-10">*/}
              {/*    <h2 className="text-sm font-medium text-gray-900">Poznámka</h2>*/}

              {/*    <div className="mt-4 space-y-6">*/}
              {/*        <p className="text-sm text-gray-600">{produkt.details}</p>*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
