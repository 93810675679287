import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/general/Home";
import SignUpPage from "./pages/SignUp";
import LogInPage from "./pages/LogIn";
import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import DashboardPage from "./pages/Dashboard";
import PasswordUpdatePage from "./pages/security/PasswordUpdate";
import LogOutPage from "./pages/Logout";
import RegisterBusinessPage from "./pages/business/RegisterBusiness";
import UpdateBusiness from "./pages/business/UpdateBusiness";
import AddressManagementPage from "./pages/address/AddressManagementPage";
import AddAddressPage from "./pages/address/AddAddressPage";
import EditAddressPage from "./pages/address/EditAddressPage";
import LoginSecurity from "./pages/security/LoginSecurity";
import BusinessManagementPage from "./pages/business/BusinessManagementPage";
import NameUpdatePage from "./pages/security/NameUpdatePage";
import EmailUpdatePage from "./pages/security/EmailUpdatePage";
import PhoneNumberUpdatePage from "./pages/security/PhoneNumberUpdatePage";
import TwoFactorManagementPage from "./pages/security/TwoFactorManagementPage";
import BusinessDashboard from "./pages/business/BusinessDashboard";
import OrdersPage from "./pages/business/OrdersPage";
import ServicesManagementPage from "./pages/business/ServicesManagementPage";
import ManageStockPage from "./pages/stock/ManageStockPage";
import AddStockPage from "./pages/stock/AddStockPage";
import EditStockPage from "./pages/stock/EditStockPage";
import ProduktPage from "./pages/general/ProduktPage";
import OtazkyPage from "./pages/general/OtazkyPage";
import CommoditiesPage from "./pages/marketplace/CommoditiesPage";
import ONasPage from "./pages/general/ONasPage";
import CommodityPage from "./pages/marketplace/CommodityPage";
import SubcategoryPage from "./pages/marketplace/SubcategoryPage";
import BasketPage from "./pages/basket/basket";
import ProductDetailPage from "./pages/marketplace/ProductDetailPage";
import FindNearby from "./pages/business/FindNearby";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    // errorElement: <ErrorPage />
    children: [
      // { path: '', element: <HomePage />},
      { index: true, element: <HomePage /> },
      { path: "produkt", element: <ProduktPage /> },
      { path: "otazky", element: <OtazkyPage /> },
      { path: "o-nas", element: <ONasPage /> },
      { path: "signup", element: <SignUpPage /> },
      { path: "login", element: <LogInPage /> },
      { path: "logout", element: <LogOutPage /> },
      { path: "dashboard", element: <DashboardPage /> },
      { path: "basket", element: <BasketPage /> },

      { path: "komodity", element: <CommoditiesPage /> },
      { path: "komodity/:commodityId", element: <CommodityPage /> },
      {
        path: "komodity/:categoryId/:subcategoryId",
        element: <SubcategoryPage />,
      },
      { path: "product/:productId", element: <ProductDetailPage /> },

      { path: "account/address", element: <AddressManagementPage /> },
      { path: "account/address/add", element: <AddAddressPage /> },
      { path: "account/address/edit", element: <EditAddressPage /> },

      { path: "account/security", element: <LoginSecurity /> },
      { path: "account/security/name-update", element: <NameUpdatePage /> },
      { path: "account/security/email-update", element: <EmailUpdatePage /> },
      {
        path: "account/security/phone-number-update",
        element: <PhoneNumberUpdatePage />,
      },
      {
        path: "account/security/password-update",
        element: <PasswordUpdatePage />,
      },
      {
        path: "account/security/2fa-manage",
        element: <TwoFactorManagementPage />,
      },

      { path: "business", element: <BusinessManagementPage /> },
      { path: "business/create", element: <RegisterBusinessPage /> },
      { path: "business/:businessId", element: <BusinessDashboard /> },
      { path: "business/findNearby", element: <FindNearby /> },
      { path: "business/:businessId/orders", element: <OrdersPage /> },
      { path: "business/:businessId/details", element: <UpdateBusiness /> },
      {
        path: "business/:businessId/services",
        element: <ServicesManagementPage />,
      },

      { path: "business/:businessId/stock", element: <ManageStockPage /> },
      { path: "business/:businessId/stock/add", element: <AddStockPage /> },
      {
        path: "business/:businessId/stock/:stockId/edit",
        element: <EditStockPage />,
      },

      { path: "*", element: <ErrorPage /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
