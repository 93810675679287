import { FC } from "react";
import { StockDTO } from "@/tapi";
import { formatPrice } from "@/helpers/formatPrice";

interface BusinessStockProps {
  stock: StockDTO[] | null;
  isPartner: boolean;
}

export const BusinessStock: FC<BusinessStockProps> = ({ stock, isPartner }) => {
  if (!stock || stock.length === 0) {
    return (
      <p className="text-muted-foreground italic">Žádné informace o zásobách</p>
    );
  }

  return (
    <div className="flex-1">
      <h3 className="font-semibold text-card-foreground mb-2 mt-4">
        Dostupné zásoby:
      </h3>
      <ul className="space-y-2">
        {stock.map((stockItem) => (
          <li
            key={stockItem.id}
            className="flex justify-between items-center text-sm"
          >
            <span className="text-card-foreground">
              {stockItem.commodity?.name}
            </span>
            <div className="flex items-center gap-3">
              <span className="text-green-700 font-medium">
                {formatPrice(stockItem.pricePerUnit)}/{stockItem.unit?.name}
              </span>
              {isPartner ? (
                <div>
                  <span className="text-muted-foreground pl-1">
                    <span className="text-green-700">
                      skl.({stockItem.quantityAvailable})
                    </span>
                  </span>
                </div>
              ) : (
                <span className="text-muted-foreground pl-1">skl.(?)</span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
