/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from "../models/Address";
import type { Business } from "../models/Business";
import type { CreateStockRequest } from "../models/CreateStockRequest";
import type { OkResult } from "../models/OkResult";
import type { Order } from "../models/Order";
import type { RatingDTO } from "../models/RatingDTO";
import type { RegisterBusiness } from "../models/RegisterBusiness";
import type { StockDTO } from "../models/StockDTO";
import type { UpdateBusiness } from "../models/UpdateBusiness";
import type { UpdateStockRequest } from "../models/UpdateStockRequest";
import type { Vehicle } from "../models/Vehicle";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class BusinessService {
  /**
   * Get a list of known businesses.
   * @returns Business Success
   * @throws ApiError
   */
  public static getBusiness(): CancelablePromise<Array<Business>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a business by ID.
   * @param businessId
   * @returns Business Success
   * @throws ApiError
   */
  public static getBusiness1(businessId: string): CancelablePromise<Business> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/{businessId}",
      path: {
        businessId: businessId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Update a business by ID.
   * @param businessId
   * @param requestBody
   * @returns Business Success
   * @throws ApiError
   */
  public static postBusiness(
    businessId: string,
    requestBody?: UpdateBusiness,
  ): CancelablePromise<Business> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Business/{businessId}",
      path: {
        businessId: businessId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Register a new business as an authenticated user.
   * @param requestBody
   * @returns Business Success
   * @throws ApiError
   */
  public static postBusinessCreate(
    requestBody?: RegisterBusiness,
  ): CancelablePromise<Business> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Business/Create",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * List stock.
   * @returns StockDTO Success
   * @throws ApiError
   */
  public static getBusinessStock(): CancelablePromise<Array<StockDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/Stock",
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a stock by stock ID.
   * @param stockId
   * @returns StockDTO Success
   * @throws ApiError
   */
  public static getBusinessStock1(
    stockId: string,
  ): CancelablePromise<StockDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/Stock/{stockId}",
      path: {
        stockId: stockId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Delete a stock by ID.
   * @param stockId
   * @returns OkResult Success
   * @throws ApiError
   */
  public static deleteBusinessStock(
    stockId: string,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Business/Stock/{stockId}",
      path: {
        stockId: stockId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a stock list of a business by business ID.
   * @param businessId
   * @returns StockDTO Success
   * @throws ApiError
   */
  public static getBusinessStock2(
    businessId: string,
  ): CancelablePromise<Array<StockDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/{businessId}/Stock",
      path: {
        businessId: businessId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Create a new stock for business.
   * @param businessId
   * @param requestBody
   * @returns StockDTO Success
   * @throws ApiError
   */
  public static postBusinessStock(
    businessId: string,
    requestBody?: CreateStockRequest,
  ): CancelablePromise<StockDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Business/{businessId}/Stock",
      path: {
        businessId: businessId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Update the stock by stock id.
   * @param businessId
   * @param stockId
   * @param requestBody
   * @returns OkResult Success
   * @throws ApiError
   */
  public static putBusinessStock(
    businessId: string,
    stockId: string,
    requestBody?: UpdateStockRequest,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/Business/{businessId}/Stock/{stockId}",
      path: {
        businessId: businessId,
        stockId: stockId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a list of orders by business by ID.
   * @param businessId
   * @returns Order Success
   * @throws ApiError
   */
  public static getBusinessOrders(
    businessId: string,
  ): CancelablePromise<Array<Order>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/{businessId}/Orders",
      path: {
        businessId: businessId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get an address by business by ID.
   * @param businessId
   * @returns Address Success
   * @throws ApiError
   */
  public static getBusinessAddress(
    businessId: string,
  ): CancelablePromise<Array<Address>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/{businessId}/Address",
      path: {
        businessId: businessId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a vehicle.
   * @param vehicleId
   * @returns Vehicle Success
   * @throws ApiError
   */
  public static getBusinessVehicles(
    vehicleId: string,
  ): CancelablePromise<Vehicle> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/Vehicles/{vehicleId}",
      path: {
        vehicleId: vehicleId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a list of vehicles.
   * @returns Order Success
   * @throws ApiError
   */
  public static getBusinessVehicles1(): CancelablePromise<Array<Order>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/Vehicles",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a list of vehicles by business by ID.
   * @param businessId
   * @returns Order Success
   * @throws ApiError
   */
  public static getBusinessVehicles2(
    businessId: string,
  ): CancelablePromise<Array<Order>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/{businessId}/Vehicles",
      path: {
        businessId: businessId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Creates a new vehicle.
   * @param businessId
   * @param requestBody
   * @returns Vehicle Created
   * @throws ApiError
   */
  public static postBusinessVehicles(
    businessId: string,
    requestBody?: Vehicle,
  ): CancelablePromise<Vehicle> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Business/{businessId}/Vehicles",
      path: {
        businessId: businessId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Deletes a vehicle.
   * @param vehicleId
   * @param businessId
   * @returns void
   * @throws ApiError
   */
  public static deleteBusinessVehicles(
    vehicleId: string,
    businessId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Business/{businessId}/Vehicles/{vehicleId}",
      path: {
        vehicleId: vehicleId,
        businessId: businessId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get all ratings for a specific business
   * @param businessId
   * @returns RatingDTO Success
   * @throws ApiError
   */
  public static getBusinessRating(
    businessId: string,
  ): CancelablePromise<Array<RatingDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Business/{businessId}/Rating",
      path: {
        businessId: businessId,
      },
    });
  }
}
