import BasketItem from "./BasketItem";
import BasketTotal from "./BasketTotal";

export default function BasketPage() {
  return (
    <>
      <div
        className="mx-auto w-full max-w-container
                  bg-white mt-6 px-6 py-24 rounded-md sm:py-32
                  scroll-m-20 sm:scroll-m-24 xl:scroll-m-32"
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h1 className="mb-10 text-center text-2xl font-bold">
              Položky v košíku
            </h1>
            <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
              <div className="rounded-lg md:w-2/3">
                <BasketItem />
                <BasketItem />
              </div>
              <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
                <BasketTotal />
                <button className="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">
                  Zaplatiť
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
