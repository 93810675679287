import { Commodity } from "../../tapi";
import ApiClient from "../../helpers/ApiClient";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import CheckBoxFilter from "../../components/CheckBoxFilter";
// import RangeFilter from "../../components/RangeFilter";

const apiClient = new ApiClient();

export default function CommoditiesPage() {
  // const [categories, setCategories] = React.useState([] as Category[]);
  const [commodities, setCommodities] = useState([] as Commodity[]);

  useEffect(() => {
    // apiClient.listCategories(true).then((categories) => {
    //     setCategories(categories);
    // }, (error) => {
    //     console.log(error);
    // });
    apiClient.getCommodities().then(
      (commodities) => {
        setCommodities(commodities);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  // function filterChangeHandler(str: string) {
  //     console.log("filterChangeHandler: " + str)
  // }

  return (
    <div
      className="mx-auto w-full max-w-container
                  bg-white mt-6 px-6 py-24 rounded-md sm:py-32
                  scroll-m-20 sm:scroll-m-24 xl:scroll-m-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h1 className="text-2xl font-bold text-gray-900">Komodity</h1>

          <div className="mt-6 grid grid-rows-10 grid-cols-4 gap-4">
            <div className="row-span-full">
              {/*https://www.hyperui.dev/components/ecommerce/collection-filters*/}
              {/*{categories.map((category) => (*/}
              {/*    <p key={category.id}>*/}
              {/*        {category.name}*/}
              {/*        <p>*/}
              {/*            {category.subcategories?.map((subcategory) => (*/}
              {/*                <p key={subcategory.id}>*/}
              {/*                    - {subcategory.name}*/}
              {/*                </p>*/}
              {/*            ))}*/}
              {/*        </p>*/}
              {/*    </p>*/}
              {/*))}*/}
              {/*<CheckBoxFilter onChange={filterChangeHandler} categories={categories}/>*/}
              {/*<RangeFilter onChange={filterChangeHandler} categories={categories}/>*/}

              {/*{categories.map((category) => (*/}
              {/*    <div key={category.uuid} className="group relative">*/}
              {/*        <Link to={"/komodity/" + category.uuid}>*/}
              {/*            <div className="mt-2 mb-9 font-semibold">*/}
              {/*                {category.name}*/}
              {/*            </div>*/}
              {/*        </Link>*/}
              {/*        <ul className="text-base text-gray-900">*/}
              {/*            {category.subcategories?.map((subcategory) => (*/}
              {/*                <li className="">*/}
              {/*                    <Link key={subcategory.uuid}*/}
              {/*                          to={"/komodity/" + category.uuid + "/" + subcategory.uuid}>*/}
              {/*                        {subcategory.name}*/}
              {/*                    </Link>*/}
              {/*                </li>*/}

              {/*            ))}*/}
              {/*        </ul>*/}
              {/*    </div>*/}
              {/*))}*/}
            </div>
            <div className="col-span-4">
              <div className="grid grid-cols-4 gap-4">
                {commodities.map((commodity) => (
                  <Link
                    to={"/komodity/" + commodity.id}
                    key={commodity.id}
                    className="col-span-1"
                  >
                    <div>
                      <p className="mt-2 mb-9 font-semibold">
                        {commodity.name}
                      </p>
                      <img
                        src={commodity.icon}
                        style={{ height: "100px", border: "1px solid black" }}
                        alt={commodity.name}
                        className="w-full h-48 object-cover"
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
