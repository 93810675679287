import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../helpers/ApiClient";

const apiClient = new ApiClient();

function LogInPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function navigateResetPasswordHandler(event: any) {
    event.preventDefault();
    navigate("/password-reset");
  }

  function loginHandler(event: any) {
    event.preventDefault();
    const request = {
      email: email,
      password: password,
    };
    apiClient.login(request).then(
      (resp) => {
        localStorage.setItem("token", resp.token as string);
        localStorage.setItem("tokenExpiration", resp.expiration as string);
        navigate("/dashboard");
        window.location.reload();
      },
      (err: any) => {
        console.log(err);
        setError(
          "Prihlásenie sa nepodarilo, email alebo heslo bolo nesprávne. Skúste to znovu.",
        );
      },
    );
  }

  function handleEmailChange(event: { target: { value: any } }) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event: { target: { value: any } }) {
    setPassword(event.target.value);
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Prihlásiť sa do svojho účtu
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={loginHandler}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Emailová adresa
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handleEmailChange}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Heslo
                </label>
                <div className="text-sm">
                  <Link
                    to="/password-reset"
                    onClick={navigateResetPasswordHandler}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Zabudli ste heslo?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={handlePasswordChange}
                />
              </div>
            </div>

            {error !== "" && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">
                  Prihlásenie sa nepodarilo!
                </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Prihlásiť sa
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Nemáš účet?{" "}
            <Link
              to="/signup"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Zaregistruj sa tu
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
export default LogInPage;
