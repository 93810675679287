import { FC } from "react";
import { getRatingText } from "@/helpers/getRating";

interface BusinessRatingProps {
  score: number;
  ratingsCount: number;
}

export const BusinessRating: FC<BusinessRatingProps> = ({
  score,
  ratingsCount,
}) => (
  <div className="flex items-center gap-2">
    <div className="flex items-center gap-1 bg-blue-600 text-white px-2 py-1 rounded-lg font-medium text-xs">
      {(score / 10).toFixed(1)}
    </div>
    <div className="flex items-center gap-1">
      <span className="text-xs font-medium text-blue-600">
        {getRatingText(score)}
      </span>
      <span className="text-xs text-muted-foreground">
        · {ratingsCount} hodnocení
      </span>
    </div>
  </div>
);
