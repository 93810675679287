import { FC } from "react";
import { Badge } from "@/components/ui/badge";
import {
  BusinessWithStockAndDistanceDTO,
  ParciumPartner,
  StockDTO,
} from "@/tapi";
import { BusinessRating } from "@/components/businessRating.tsx";
import { BusinessStock } from "./BusinessStock";
import { BusinessInfo } from "./BusinessInfo";
import { BusinessActions } from "./BusinessActions";

interface BusinessCardProps {
  business: BusinessWithStockAndDistanceDTO;
}

export const BusinessCard: FC<BusinessCardProps> = ({ business }) => {
  const isPartner = business.parciumPartnership === ParciumPartner.PARTNERED;

  return (
    <div
      className={`bg-card rounded-lg overflow-hidden flex flex-col h-full relative
             hover:scale-[1.02] transition-all duration-200 ease-out
             animate-in slide-in-from-bottom-2
             data-[state=open]:animate-in data-[state=closed]:animate-out
             data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 duration-150
             data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 ease-in-out
             data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-left-1/2
             data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-top-[48%]
             ${isPartner ? "shadow-xl border-2 border-primary" : "shadow-lg border border-border"}`}
    >
      {isPartner && (
        <Badge
          variant="default"
          className="absolute top-2 -left-3 bg-gradient-to-r from-primary to-primary/80 text-primary-foreground font-semibold pl-5"
        >
          Partner
        </Badge>
      )}

      <div className="p-6 flex flex-col flex-1 pt-8">
        <div>
          <h2 className="text-xl font-bold text-card-foreground">
            {business.businessName}
          </h2>
          {business.averageScore && business.ratings && (
            <BusinessRating
              score={business.averageScore}
              ratingsCount={business.ratings.length}
            />
          )}
        </div>

        <BusinessInfo
          distanceInMeters={business.distanceInMeters}
          pricePerKm={business.pricePerKm}
        />

        <BusinessStock
          stock={business.availableStock ?? new Array<StockDTO>()}
          isPartner={isPartner}
        />

        <BusinessActions
          isPartner={isPartner}
          businessId={business.businessId}
        />
      </div>
    </div>
  );
};

export default BusinessCard;
