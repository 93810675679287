import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Commodity, StockDTO, UpdateStockRequest } from "../../tapi";
import ApiClient from "../../helpers/ApiClient";

const apiClient = new ApiClient();

export default function EditStockPage() {
  const params = useParams<{ businessId: string; stockId: string }>();
  const businessId = String(params.businessId).valueOf();
  const stockId = String(params.stockId).valueOf();

  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [updateStockRequest, setUpdateStockRequest] = React.useState(
    {} as UpdateStockRequest,
  );
  const [commodity, setCommodity] = React.useState({} as Commodity);

  useEffect(() => {
    apiClient
      .getStockByStockId(stockId)
      .then((stock: StockDTO) => {
        console.log(stock);
        const updateStockRq: UpdateStockRequest = {
          description: stock.description,
          externalId: stock.externalId,
          quantity: stock.quantity,
          quantityIncrement: stock.quantityIncrement,
          quantityAvailable: stock.quantityAvailable,
          pricePerUnitExVat: stock.pricePerUnitExVat,
          archived: stock.archived,
          addressId: stock.storedAddress,
        } as UpdateStockRequest;
        setUpdateStockRequest(updateStockRq);

        apiClient
          .getCommodity(String(stock.commodityId))
          .then((commodity) => {
            console.log(commodity);
            setCommodity(commodity);
          })
          .catch((err) => {
            console.log(err);
            setError(err);
          });
      })
      .catch((err: React.SetStateAction<string>) => {
        console.log(err);
        setError(err);
      });
  }, [stockId]);

  function UpdateStockHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log(updateStockRequest);
    apiClient
      .updateStock(businessId, stockId, updateStockRequest)
      .then(() => {
        setError("");
        navigate("/business/" + businessId + "/stock");
        window.location.reload();
      })
      .catch((err: React.SetStateAction<string>) => {
        console.log(err);
        setError(err);
      });
  }

  const inputChangeHandler = (input: string, value: number | string) => {
    setUpdateStockRequest((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Upraviť tovar v sklade
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={UpdateStockHandler}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Komodita *
              </label>
              <div className="mt-2">
                Názov: {commodity.name}
                <br />
                Jednotky: {commodity.unit?.name}
              </div>
            </div>

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Popis tovaru *
              </label>
              <p className="text-sm text-gray-500">
                Popis tovaru, ktorú chcete upraviť v sklade.
              </p>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler("description", event.target.value)
                  }
                  id="description"
                  name="description"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    updateStockRequest.description
                      ? updateStockRequest.description
                      : ""
                  }
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="quantity"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Množstvo *
              </label>
              <p className="text-sm text-gray-500">
                Množstvo tovaru, ktorú chcete pridať do skladu.
              </p>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler("quantity", Number(event.target.value))
                  }
                  id="quantity"
                  name="quantity"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    updateStockRequest.quantity
                      ? updateStockRequest.quantity
                      : ""
                  }
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="quantityIncrement"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Najmenšie množstvo na objednávku *
              </label>
              <p className="text-sm text-gray-500">
                Najmenšie množstvo tovaru, ktorú je možné objednať. Ak chce
                zákazník objednať viac, možno to urobiť v násobkoch tohto
                množstva.
              </p>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler(
                      "quantityIncrement",
                      Number(event.target.value),
                    )
                  }
                  id="quantityIncrement"
                  name="quantityIncrement"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    updateStockRequest.quantityIncrement
                      ? updateStockRequest.quantityIncrement
                      : ""
                  }
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Cena za jednotku bez DPH *
              </label>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler("pricePerUnitExVat", event.target.value)
                  }
                  id="pricePerUnitExVat"
                  name="pricePerUnitExVat"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    updateStockRequest.pricePerUnitExVat
                      ? updateStockRequest.pricePerUnitExVat
                      : ""
                  }
                />
              </div>
            </div>

            {/*<div>*/}
            {/*    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">*/}
            {/*        Obrázok tovaru **/}
            {/*    </label>*/}
            {/*    <div className="mt-2">*/}
            {/*        <input*/}
            {/*            onChange={(event) => inputChangeHandler( "image", event.target.value)}*/}
            {/*            id="image"*/}
            {/*            name="image"*/}
            {/*            type="text"*/}
            {/*            required*/}
            {/*            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
            {/*            defaultValue={updateStockRequest.image ? updateStockRequest.image : ""}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div>
              Rozmery tovaru
              <p className="text-sm text-gray-500">
                V prípade, že tovar má rozmery, je možné ich uviesť.
              </p>
            </div>

            {error !== "" && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">
                  Upravenie tovaru sa nepodarilo!
                </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Upraviť tovar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
