import { FC } from "react";
import { formatDistance, calculateTransportPrice } from "@/helpers/distance";

interface BusinessInfoProps {
  distanceInMeters: number | null | undefined;
  pricePerKm: number | null | undefined;
}

export const BusinessInfo: FC<BusinessInfoProps> = ({
  distanceInMeters,
  pricePerKm,
}) => (
  <div className="mt-2 space-y-2 text-sm text-muted-foreground">
    <p>Vzdálenost: {formatDistance(distanceInMeters)}</p>
    <p>Doprava: {calculateTransportPrice(pricePerKm, distanceInMeters)}</p>
  </div>
);
