import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Address, AddressResponse, Business, UpdateBusiness } from "../../tapi";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import ApiClient from "../../helpers/ApiClient";

const client = new ApiClient();

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function UpdateBusinessPage() {
  const navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [business, setBusiness] = React.useState<Business>({} as Business);
  const [selected, setSelected] = React.useState({} as Address);
  const [addresses, setAddresses] = React.useState([] as AddressResponse[]);

  const businessUuid = window.location.pathname.split("/")[2];

  useEffect(() => {
    client.getBusiness(businessUuid).then(
      (business) => {
        setBusiness(business);
        client.getAddressesMe().then(
          (addresses) => {
            setAddresses(addresses);
            if (business.defaultAddressId !== null) {
              setSelected(
                addresses.filter(
                  (x) => x.address.id === business.defaultAddressId,
                )[0].address,
              );
            }
            console.log(addresses);
          },
          (error) => {
            console.log(error);
          },
        );
      },
      (error) => {
        console.log(error);
      },
    );
  }, [businessUuid, success]);

  function BusinessUpdateHandler(event: any) {
    event.preventDefault();
    const updateBusiness = {
      name: business.name,
      ico: business.ico,
      defaultAddressId: selected.id,
      crmName: business.crmName,
    } as UpdateBusiness;
    client.sendBusinessUpdate(businessUuid, updateBusiness).then(
      () => {
        setSuccess(true);
        setError("");
        navigate("/business/" + businessUuid, { replace: true });
      },
      (err: any) => {
        console.log(err);
        setSuccess(false);
        setError(
          "Zmena údajov spoločnosti sa nezdarila, niečo je nesprávne. Skúste to znovu.",
        );
      },
    );
  }

  function inputChangeHandler(name: string, value: string) {
    setBusiness((prevBusiness) => {
      return {
        ...prevBusiness,
        [name]: value,
      } as Business;
    });
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Upraviť detaily spoločnosti
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {success && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Úspech!</strong>
              <br />
              <span className="block sm:inline">
                Nastavenie nových detailov spoločnosti sa podarilo
              </span>
            </div>
          )}
          {!success && (
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={BusinessUpdateHandler}
            >
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Meno spoločnosti *
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={business.name ? business.name : ""}
                    onChange={(e) => inputChangeHandler("name", e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="ico"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    IČO *
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="ico"
                    name="ico"
                    type="text"
                    autoComplete="ico"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={business.ico ? business.ico : ""}
                    onChange={(e) => inputChangeHandler("ico", e.target.value)}
                  />
                </div>
              </div>

              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                      Assigned to
                    </Listbox.Label>
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <HomeIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-3 block truncate">
                            {selected.id ? (
                              <>
                                {selected.addressLine1}
                                <br />
                                {selected.addressLine2 ? (
                                  <>
                                    {selected.addressLine2}
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                {selected.postcode} {selected.city}
                                <br />
                                {selected.county ? (
                                  <>
                                    {selected.county}
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                {selected.phoneNumber ? (
                                  <>
                                    {selected.phoneNumber}
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              "žiadna"
                            )}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {addresses.map((addressResponse) => (
                            <Listbox.Option
                              key={addressResponse.address.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9",
                                )
                              }
                              value={addressResponse.address}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <HomeIcon
                                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate",
                                      )}
                                    >
                                      {addressResponse.address.addressLine1}
                                      <br />
                                      {addressResponse.address.addressLine2 ? (
                                        <>
                                          {addressResponse.address.addressLine2}
                                          <br />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {addressResponse.address.postcode}{" "}
                                      {addressResponse.address.city}
                                      <br />
                                      {addressResponse.address.county ? (
                                        <>
                                          {addressResponse.address.county}
                                          <br />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {addressResponse.address.phoneNumber ? (
                                        <>
                                          {addressResponse.address.phoneNumber}
                                          <br />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>

              {error !== "" && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Vyskytla sa chyba!</strong>
                  <br />
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Uložiť detaily spoločnosti
                </button>
              </div>
            </form>
          )}

          <p className="mt-10 text-center text-sm text-gray-500">
            <Link
              to={"/business/" + businessUuid}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Späť <span aria-hidden="true">→</span>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default UpdateBusinessPage;
