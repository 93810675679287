import { FC, ReactNode } from "react";

interface HeroFeatureProps {
  icon: ReactNode;
  title: string;
  description: string;
}

export const HeroFeature: FC<HeroFeatureProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="flex items-start gap-4">
    <div className="p-3 bg-white/10 rounded-lg">{icon}</div>
    <div>
      <h3 className="font-semibold mb-2">{title}</h3>
      <p className="text-white/80 text-sm">{description}</p>
    </div>
  </div>
);
