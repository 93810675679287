import React, { Fragment, useEffect } from "react";
import { Commodity, CreateStockRequest, StockDTO } from "../../tapi";
import { useNavigate, useParams } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/20/solid";
import CommodityOption from "../../components/CommodityOption";
import ApiClient from "../../helpers/ApiClient";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const apiClient = new ApiClient();

export default function AddStockPage() {
  const { businessId } = useParams<{ businessId: string }>();
  const navigate = useNavigate();
  const [createStockRq, setCreateStockRq] = React.useState(
    {} as CreateStockRequest,
  );
  const [error, setError] = React.useState("");
  const [commodities, setCommodities] = React.useState([] as Commodity[]);
  const [selected, setSelected] = React.useState({} as Commodity);

  function addStockHandler(event: any) {
    event.preventDefault();

    apiClient.getBusiness(String(businessId)).then((business) => {
      const createStockRequest = {
        ...createStockRq,
        availableQuantity: createStockRq.quantity,
        businessId: String(businessId),
        commodityId: String(selected.id),
        storedAtAddressId: String(business.defaultAddressId),
      } as CreateStockRequest;
      console.log(createStockRequest);

      apiClient
        .addStock(String(businessId), createStockRequest)
        .then((stock: StockDTO) => {
          console.log(stock);
          setError("");
          navigate("/business/" + businessId + "/stock");
          window.location.reload();
        })
        .catch((err: React.SetStateAction<string>) => {
          console.log(err);
          setError(err);
        });
    });
  }

  useEffect(() => {
    apiClient
      .getCommodities()
      .then((resp) => {
        setCommodities(resp);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, []);

  function inputChangeHandler(name: string, value: string | number) {
    console.log(value + " " + typeof value);
    setCreateStockRq((prevStock) => {
      return {
        ...prevStock,
        [name]: value,
      } as CreateStockRequest;
    });
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Pridať tovar do skladu
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={addStockHandler}
          >
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    Komodita *
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                      <span className="flex items-center">
                        <Square3Stack3DIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3 block truncate">
                          {selected.id ? (
                            <CommodityOption commodity={selected} />
                          ) : (
                            "vyberte komoditu"
                          )}
                        </span>
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {commodities.map((commodity) => (
                          <Listbox.Option
                            key={commodity.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "bg-indigo-600 text-white"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-3 pr-9",
                              )
                            }
                            value={commodity}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <Square3Stack3DIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate",
                                    )}
                                  >
                                    <CommodityOption commodity={commodity} />
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4",
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Popis tovaru
              </label>
              <p className="text-sm text-gray-500">
                Krátky popis tovaru, ktorý chcete pridať do skladu.
              </p>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler("description", event.target.value)
                  }
                  id="description"
                  name="description"
                  type="text"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    createStockRq.description ? createStockRq.description : ""
                  }
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="quantity"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Množstvo *
              </label>
              <p className="text-sm text-gray-500">
                Množstvo tovaru, ktorú chcete pridať do skladu.
              </p>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler("quantity", Number(event.target.value))
                  }
                  id="quantity"
                  name="quantity"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    createStockRq.quantity ? createStockRq.quantity : ""
                  }
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="quantityIncrement"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Najmenšie množstvo na objednávku *
              </label>
              <p className="text-sm text-gray-500">
                Najmenšie množstvo tovaru, ktorú je možné objednať. Ak chce
                zákazník objednať viac, možno to urobiť v násobkoch tohto
                množstva.
              </p>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler(
                      "quantityIncrement",
                      Number(event.target.value),
                    )
                  }
                  id="quantityIncrement"
                  name="quantityIncrement"
                  type="number"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    createStockRq.quantityIncrement
                      ? createStockRq.quantityIncrement
                      : ""
                  }
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="pricePerUnitExVat"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Cena za jednotku s DPH *
              </label>
              <div className="mt-2">
                <input
                  onChange={(event) =>
                    inputChangeHandler("pricePerUnitExVat", event.target.value)
                  }
                  id="pricePerUnitExVat"
                  name="pricePerUnitExVat"
                  type="text"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={
                    createStockRq.pricePerUnitExVat
                      ? createStockRq.pricePerUnitExVat
                      : ""
                  }
                />
              </div>
            </div>

            {error !== "" && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">
                  Pridanie tovaru sa nepodarilo!
                </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Pridať tovar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
