/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from "../models/Address";
import type { AddressDTO } from "../models/AddressDTO";
import type { IdentityResult } from "../models/IdentityResult";
import type { OkResult } from "../models/OkResult";
import type { RegisterRequest } from "../models/RegisterRequest";
import type { SignInRequest } from "../models/SignInRequest";
import type { SignInResponse } from "../models/SignInResponse";
import type { UpdateAddressRequest } from "../models/UpdateAddressRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class IdentityService {
  /**
   * Parcium User SignIn
   * @param requestBody
   * @returns SignInResponse Success
   * @throws ApiError
   */
  public static postIdentityAuthenticationSignIn(
    requestBody?: SignInRequest,
  ): CancelablePromise<SignInResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Identity/Authentication/SignIn",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
    });
  }

  /**
   * Create a new user account.
   * @param requestBody
   * @returns IdentityResult Created
   * @throws ApiError
   */
  public static postIdentityAuthenticationRegister(
    requestBody?: RegisterRequest,
  ): CancelablePromise<IdentityResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Identity/Authentication/Register",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Get an address based on ID.
   * @param addressId
   * @returns AddressDTO Success
   * @throws ApiError
   */
  public static getIdentityAddresses(
    addressId: string,
  ): CancelablePromise<AddressDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Identity/Addresses/{addressId}",
      path: {
        addressId: addressId,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Update an address.
   * @param addressId
   * @param requestBody
   * @returns Address Success
   * @throws ApiError
   */
  public static putIdentityAddresses(
    addressId: string,
    requestBody?: UpdateAddressRequest,
  ): CancelablePromise<Address> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/Identity/Addresses/{addressId}",
      path: {
        addressId: addressId,
      },
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * [DEVELOPER] Delete an address.
   * @param addressId
   * @returns OkResult Success
   * @throws ApiError
   */
  public static deleteIdentityAddresses(
    addressId: string,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/Identity/Addresses/{addressId}",
      path: {
        addressId: addressId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns Address Success
   * @throws ApiError
   */
  public static postIdentityAddresses(
    requestBody?: Address,
  ): CancelablePromise<Address> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Identity/Addresses",
      body: requestBody,
      mediaType: "application/json;odata.metadata=minimal;odata.streaming=true",
      errors: {
        401: `Unauthorized`,
      },
    });
  }
}
