import { useEffect, useState } from "react";

import ApiClient from "@/helpers/ApiClient";
import { BusinessWithStockAndDistanceDTO, Category, Commodity } from "@/tapi";
import { Hero } from "@/components/Hero.tsx";
import { SearchBar } from "@/components/SearchBar.tsx";
import BusinessCard from "@/components/BusinessCards.tsx";

const client = new ApiClient();

function NearbyBusinessesSearch(): JSX.Element {
  const [searchType, setSearchType] = useState<"category" | "commodity">(
    "category",
  );
  const [categories, setCategories] = useState<Category[]>([]);
  const [commodities, setCommodities] = useState<Commodity[]>([]);
  const [selectedItem, setSelectedItem] = useState<Category | Commodity | null>(
    null,
  );
  const [zipCode, setZipCode] = useState<string>("");
  const [businesses, setBusinesses] = useState<
    BusinessWithStockAndDistanceDTO[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showHero, setShowHero] = useState<boolean>(true);

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    if (isMobile) {
      setIsSearching(true);
      setShowHero(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const [categoriesData, commoditiesData] = await Promise.all([
          client.listCategories(false),
          client.getCommodities(),
        ]);
        setCategories(categoriesData);
        setCommodities(commoditiesData);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Nepodařilo se načíst data. Prosím zkuste to znovu.");
      }
    };

    fetchData();
  }, []);

  const handleSearchTypeToggle = (checked: boolean): void => {
    setSearchType(checked ? "commodity" : "category");
    setSelectedItem(null);
    setBusinesses([]);
    setError("");
    setIsSearching(false);
    setShowHero(true);
  };

  const handleItemSelect = (itemId: string): void => {
    const items = searchType === "category" ? categories : commodities;
    const selected = items.find((item) => item.id === itemId);
    if (selected) {
      setSelectedItem(selected);
    }
  };

  const searchNearbyBusinesses = async (): Promise<void> => {
    if (!selectedItem || !zipCode) return;

    setIsSearching(true);
    setShowHero(false);
    setLoading(true);
    setError("");

    try {
      const zipResponse = await fetch(
        `https://api.zippopotam.us/cz/${zipCode}`,
      );
      if (!zipResponse.ok) {
        throw new Error("Neplatné PSČ");
      }

      const zipData = await zipResponse.json();
      const { longitude, latitude } = zipData.places[0];

      const searchResults = await client.getParciumFindNearby(
        parseFloat(latitude),
        parseFloat(longitude),
        10,
        searchType === "category" ? selectedItem.id : undefined,
        searchType === "commodity" ? selectedItem.id : undefined,
      );

      setBusinesses(searchResults);
    } catch (err) {
      console.error("Error:", err);
      setError(
        err instanceof Error
          ? err.message
          : "Došlo k chybě. Prosím zkuste to znovu.",
      );
      setIsSearching(false);
      setShowHero(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={showHero ? "bg-primary" : ""}>
      <div className="container mx-auto md:px-4 px-0">
        <div className="relative">
          <Hero isSearching={isSearching} showHero={showHero} />
          <SearchBar
            searchType={searchType}
            onSearchTypeChange={handleSearchTypeToggle}
            selectedItem={selectedItem}
            categories={categories}
            commodities={commodities}
            onItemSelect={handleItemSelect}
            zipCode={zipCode}
            onZipCodeChange={(value) => setZipCode(value)}
            onSearch={searchNearbyBusinesses}
            loading={loading}
            isSearching={isSearching}
          />
        </div>

        {error && (
          <div
            className="mb-6 p-4 bg-destructive/10 border border-destructive/20 rounded-md 
                    animate-in slide-in-from-top duration-300"
          >
            <p className="text-destructive">{error}</p>
          </div>
        )}

        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6
                transition-all duration-500 ease-in-out
                ${businesses.length > 0 ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"}`}
        >
          {businesses.map((business) => (
            <BusinessCard key={business.businessId} business={business} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NearbyBusinessesSearch;
