// helpers/formatPrice.ts
export const formatPrice = (price: string | null | undefined): string => {
  if (!price) return "Cena neuvedena";
  return new Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency: "CZK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(parseFloat(price));
};
