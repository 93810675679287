import { Link } from "react-router-dom";
import { ApiResult } from "../tapi/core/ApiResult";
import ApiClient from "../helpers/ApiClient";
import { useState } from "react";

const apiClient = new ApiClient();
function SignUpPage() {
  const [wasRegistrationSubmitted, setWasRegistrationSubmitted] =
    useState(false);
  const [wasRegistrationSuccessful, setWasRegistrationSuccessful] =
    useState(false);
  const [registerUser, setRegisterUser] = useState({
    fullName: "",
    userName: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  async function registrationHandler(event: any): Promise<void> {
    event.preventDefault();
    apiClient.register(registerUser).then(
      () => {
        setWasRegistrationSuccessful(true);
        setWasRegistrationSubmitted(true);
      },
      (err: ApiResult) => {
        if (err.status === 409) {
          setError("Registrácia sa nepodarila, skúste to prosím neskôr.");
        } else {
          console.log(err.body);
          setError(
            err.body
              .map((i: { description: any }) => i.description + "\r\n")
              .toString(),
          );
        }
        setWasRegistrationSuccessful(false);
        setWasRegistrationSubmitted(true);
      },
    );
  }

  const inputChangeHandler = (input: string, value: string) => {
    setRegisterUser((prevState: any) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Registrácia nového užívateľa
          </h2>
        </div>

        {wasRegistrationSuccessful && (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center"
            role="alert"
          >
            <strong className="font-bold">Úspech!</strong>
            <br />
            <span className="block sm:inline">
              Registrácia prebehla úspešne, môžete sa{" "}
              <Link
                to="/login"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                prihlásiť
              </Link>
              .
            </span>
          </div>
        )}
        {((wasRegistrationSubmitted && !wasRegistrationSuccessful) ||
          !wasRegistrationSubmitted) && (
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={registrationHandler}
            >
              <div>
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Meno a priezvisko
                </label>
                <div className="mt-2">
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    autoComplete="fullName"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) =>
                      inputChangeHandler("fullName", event.target.value)
                    }
                    value={registerUser.fullName}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="userName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Užívateľské meno
                </label>
                <div className="mt-2">
                  <input
                    id="userName"
                    name="userName"
                    type="text"
                    autoComplete="userName"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) =>
                      inputChangeHandler("userName", event.target.value)
                    }
                    value={registerUser.userName}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Emailová adresa
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) =>
                      inputChangeHandler("email", event.target.value)
                    }
                    value={registerUser.email}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Heslo
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) =>
                      inputChangeHandler("password", event.target.value)
                    }
                    value={registerUser.password}
                  />
                </div>
              </div>

              {error !== "" && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center"
                  role="alert"
                >
                  <strong className="font-bold">
                    Registrácia sa nepodarila!
                  </strong>
                  <br />
                  <span className="block sm:inline">{error}</span>
                </div>
              )}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Registrácia
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              <Link
                to="/login"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Späť na prihlásenie
              </Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
}
export default SignUpPage;
