import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import classes from "../Dashboard.module.css";
import { Business } from "../../tapi";
import ApiClient from "../../helpers/ApiClient";

function BusinessDashboard() {
  let { businessId } = useParams<{ businessId: string }>();
  const [business, setBusiness] = React.useState({} as Business);

  useEffect(() => {
    if (businessId === undefined) {
      return;
    }
    new ApiClient().getBusiness(businessId).then(
      (resp) => {
        setBusiness(resp);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [businessId]);

  return (
    <div className="mx-auto max-w-6xl">
      <div className="text-center mb-20">
        <h1 className="text-4xl tracking-tight text-gray-900 sm:text-4xl">
          Dashboard - {business.name}
        </h1>
      </div>
      <div className="grid gap-4 grid-cols-2">
        <Link to={"/business/" + businessId + "/orders"}>
          <div
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}
          >
            <h2 className="text-gray-800 text-2xl">Spravovanie objednávok</h2>
            <p className="mt-2 text-gray-600">
              TODO: Sledujte stav objednávok, vystavte faktúry, ...
            </p>
          </div>
        </Link>

        {/*<Link to="/account/address">*/}
        {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
        {/*        <h2 className="text-gray-800 text-2xl">Vaše adresy</h2>*/}
        {/*        <p className="mt-2 text-gray-600">Upraviť, odstrániť alebo nastaviť predvolenú adresu Vášho podniku</p>*/}
        {/*    </div>*/}
        {/*</Link>*/}
        {/*<Link to="">*/}
        {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
        {/*        <h2 className="text-gray-800 text-2xl">Vaše platby</h2>*/}
        {/*        <p className="mt-2 text-gray-600">TODO: Zmeniť alebo pridať platobné údaje a vidieť transakcie</p>*/}
        {/*    </div>*/}
        {/*</Link>*/}
        {/*<Link to="">*/}
        {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
        {/*        <h2 className="text-gray-800 text-2xl">Vaše správy</h2>*/}
        {/*        <p className="mt-2 text-gray-600">TODO: Vidieť alebo odpovedať na správy z Parcia,*/}
        {/*            predávajúcich a kupujúcich</p>*/}
        {/*    </div>*/}
        {/*</Link>*/}
        {/*<Link to="">*/}
        {/*    <div className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}>*/}
        {/*        <h2 className="text-gray-800 text-2xl">Zákaznícke služby</h2>*/}
        {/*        <p className="mt-2 text-gray-600">TODO: Prezrieť dokumentáciu, možnosti alebo nás kontaktovať</p>*/}
        {/*    </div>*/}
        {/*</Link>*/}
        <Link to={"/business/" + businessId + "/details"}>
          <div
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}
          >
            <h2 className="text-gray-800 text-2xl">
              Upraviť údaje o spoločnosti
            </h2>
            <p className="mt-2 text-gray-600">
              Spravujte detaily Vašej spoločnosti
            </p>
          </div>
        </Link>

        <Link to={"/business/" + businessId + "/stock"}>
          <div
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}
          >
            <h2 className="text-gray-800 text-2xl">Skladový manažment</h2>
            <p className="mt-2 text-gray-600">
              Pridajte, upravte, alebo spravujte svôj sklad komodít
            </p>
          </div>
        </Link>

        <Link to={"/business/" + businessId + "/services"}>
          <div
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.dashboardItem}`}
          >
            <h2 className="text-gray-800 text-2xl">Transportné služby</h2>
            <p className="mt-2 text-gray-600">
              TODO: Spolupracujte s Parcium a ponúknite Vaše služby na
              transportovanie komodít.
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default BusinessDashboard;
