import { FC } from "react";
import { Search, Building2, BadgeCheck } from "lucide-react";
import { HeroFeature } from "./HeroFeature";

interface HeroProps {
  isSearching: boolean;
  showHero: boolean;
}

export const Hero: FC<HeroProps> = ({ isSearching, showHero }) => {
  if (!showHero) return null;

  return (
    <div
      className={`relative overflow-hidden transition-all duration-500 ease-in-out 
            ${isSearching ? "opacity-0 h-0" : "opacity-100"}`}
    >
      <div className="flex flex-col justify-center text-white bg-primary p-12 pb-24 rounded-b-lg">
        <h1 className="text-4xl font-bold mb-6">
          Najděte nejlepší dodavatele ve vašem okolí
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <HeroFeature
            icon={<Search className="w-6 h-6" />}
            title="Snadné vyhledávání"
            description="Zadejte PSČ a kategorii nebo komoditu pro nalezení dodavatelů ve vašem okolí"
          />
          <HeroFeature
            icon={<Building2 className="w-6 h-6" />}
            title="Porovnání cen"
            description="Snadno porovnejte ceny a dostupnost mezi různými dodavateli"
          />
          <HeroFeature
            icon={<BadgeCheck className="w-6 h-6" />}
            title="Ověření partneři"
            description="Spolehliví dodavatelé s garantovanou dostupností a cenami"
          />
        </div>
      </div>
    </div>
  );
};
