/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterOrderDTO } from "../models/MasterOrderDTO";
import type { OkResult } from "../models/OkResult";
import type { Order } from "../models/Order";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class CheckoutService {
  /**
   * Start the checkout process from your basket.
   * @returns MasterOrderDTO Success
   * @throws ApiError
   */
  public static postCheckoutOrders(): CancelablePromise<MasterOrderDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Checkout/Orders",
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get master order by ID.
   * @param masterOrderId
   * @returns MasterOrderDTO Success
   * @throws ApiError
   */
  public static getCheckoutOrdersMaster(
    masterOrderId: string,
  ): CancelablePromise<MasterOrderDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Checkout/Orders/Master/{masterOrderId}",
      path: {
        masterOrderId: masterOrderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get order by ID.
   * @param orderId
   * @returns Order Success
   * @throws ApiError
   */
  public static getCheckoutOrders(orderId: string): CancelablePromise<Order> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/Checkout/Orders/{orderId}",
      path: {
        orderId: orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Confirm an order.
   * @param orderId
   * @returns OkResult Success
   * @throws ApiError
   */
  public static postCheckoutOrdersConfirm(
    orderId: string,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Checkout/Orders/{orderId}/Confirm",
      path: {
        orderId: orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Cancel an order.
   * @param orderId
   * @returns OkResult Success
   * @throws ApiError
   */
  public static postCheckoutOrdersCancel(
    orderId: string,
  ): CancelablePromise<OkResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/Checkout/Orders/{orderId}/Cancel",
      path: {
        orderId: orderId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
