import { Address, AddressResponse } from "../../tapi";
import AddressForm from "../../components/addressForm";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";
import { useState } from "react";

const initialAddressResponse = {
  address: {} as Address,
  isDefault: false,
} as AddressResponse;
const apiClient = new ApiClient();

function AddAddressPage() {
  const navigate = useNavigate();
  const [address] = useState<AddressResponse>(initialAddressResponse);

  function addAddressHandler(address: Address) {
    let newAddress = {
      fullName: address.fullName,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      postcode: address.postcode,
      county: address.county,
      phoneNumber: address.phoneNumber,
      openingHours: address.openingHours,
    } as Address;
    apiClient.addAddress(newAddress).then(
      () => {
        navigate("/account/address");
      },
      (error) => {
        console.log(error);
      },
    );
  }

  return (
    <div className="mx-auto max-w-6xl">
      <h2 className="mt-6 text-2xl leading-8 text-gray-600">
        Pridať novú adresu
      </h2>
      <AddressForm onSubmit={addAddressHandler} addressResponse={address} />
    </div>
  );
}

export default AddAddressPage;
