import {
  BanknotesIcon,
  BeakerIcon,
  ChatBubbleLeftRightIcon,
  CubeTransparentIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function ONasPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white mt-6 px-6 py-24 rounded-md sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                O nás
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Zlepšovanie situácie obchodu s komoditami
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Parcium je Váš dôveryhodný partner vo svete obchodovania s
                komoditami. Na základe vízie revolučného zjednodušenia trhu s
                komoditami sme sa zaviazali poskytovať platformu, ktorá umožňuje
                lepšiu situáciu obchodníkom, firmám a kupujúcim v celej krajine.
                Tu je pohľad na to, kto sme a čo zastávame:
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/images/onas-building-materials.jpg"
            alt="Lumber commodity"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Naša misia:
              </h2>
              <p>
                V Parciume je naša misia jasná – vytvoriť dynamický a bezpečný
                online trh, ktorý preklenie priepasť medzi kupujúcimi a
                predávajúcimi v komoditnom priemysle. Naším cieľom je podporovať
                inovácie, transparentnosť a etické obchodné praktiky a zároveň
                poskytovať bezproblémové obchodné skúsenosti.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Naše hodnoty:
              </h2>
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CubeTransparentIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Transparentnosť.{" "}
                    </strong>
                    Veríme v otvorené a čestné transakcie. Základom našich
                    operácií je transparentnosť, ktorá zabezpečuje dôveru medzi
                    našimi používateľmi.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <BeakerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Inovácia.{" "}
                    </strong>
                    Prijímame technológie a inovácie, aby sme zostali na čele
                    odvetvia obchodovania s komoditami. Náš záväzok neustáleho
                    zlepšovania nás poháňa poskytovať špičkové riešenia.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <BanknotesIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Bezúhonnosť.{" "}
                    </strong>
                    Svoje podnikanie vedieme čestne, dodržiavame etické normy a
                    zodpovedné obchodné praktiky.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ChatBubbleLeftRightIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Zameranie na zákazníka.{" "}
                    </strong>
                    Naši používatelia sú v centre všetkého, čo robíme.
                    Uprednostňujeme vaše potreby, ponúkame užívateľsky prívetivé
                    rozhranie, robustnú zákaznícku podporu a cenné komodity.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Náš prístup:
              </h2>
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Splnomocnenie.{" "}
                    </strong>
                    Poskytujeme prístup k širokému spektru komodít, prehľadom
                    trhu a nástrojom, jednotlivcom a firmám.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Vzdelávanie.{" "}
                    </strong>
                    Veríme, že našich používateľov vzdelávame, aby robili
                    informované rozhodnutia. Naša platforma ponúka vzdelávacie
                    blogy, analýzu trhu a odborné poznatky.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Celoštátny dosah.{" "}
                    </strong>
                    Spájame obchodníkov a podniky po celej republike, čím
                    uľahčujeme obchod po celom území a podporujeme cezhraničnú
                    spoluprácu.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Prečo s nami spolupracovať:
              </h2>
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Bezkonkurenčná odbornosť.{" "}
                    </strong>
                    Náš tím pozostáva z odborníkov s dlhoročnými skúsenosťami v
                    online obchodovaní s komoditami, vďaka čomu získate tie
                    najlepšie služby.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Špičková technológia.{" "}
                    </strong>
                    Využívame najmodernejšie technológie na poskytovanie
                    bezproblémového a efektívneho obchodovania.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Bezpečnosť.{" "}
                    </strong>
                    Vaše údaje a transakcie sú chránené špičkovými
                    bezpečnostnými opatreniami.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Komunita.{" "}
                    </strong>
                    Pripojte sa k prosperujúcej komunite podobne zmýšľajúcich
                    obchodníkov, firiem a kupujúcich.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Náš záväzok k udržateľnosti.{" "}
                    </strong>
                    Uvedomujeme si dôležitosť udržateľného a zodpovedného
                    obchodovania. Parcium ponúka možnosti pre ekologické a
                    eticky získavané komodity, čo odráža náš záväzok k lepšej
                    budúcnosti.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                Parcium je viac než len platforma na obchodovanie s komoditami;
                je to centrum možností. Pozývame vás, aby ste sa stali súčasťou
                našej cesty, keď spoločne pretvárame komoditný priemysel.{" "}
                <Link to="/signup" className="font-semibold text-indigo-600">
                  Pridajte sa k nám{" "}
                </Link>
                ešte dnes a zažite budúcnosť obchodovania s komoditami.
              </p>
              <p className="mt-6">
                Ďakujeme, že ste si vybrali Parcium ako svojho dôveryhodného
                partnera. Tešíme sa na podporu vášho úspechu vo svete obchodu s
                komoditami.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
