import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AddressResponse } from "../../tapi";
import classes from "./AddressManagementPage.module.css";
import ApiClient from "../../helpers/ApiClient";

const apiClient = new ApiClient();
function AddressManagementPage() {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState<AddressResponse[]>(
    [] as AddressResponse[],
  );

  function navigateAddAddressHandler(event: any) {
    event.preventDefault();
    navigate("/account/address/add");
  }

  function editAddressHandler(event: any) {
    event.preventDefault();
    const uuid = event.target.getAttribute("data-uuid");
    navigate("/account/address/edit?uuid=" + uuid);
  }

  function deleteAddressHandler(event: any) {
    event.preventDefault();
    const uuid = event.target.getAttribute("data-uuid");
    apiClient.deleteAddress(uuid).then(
      () => {
        setAddresses(addresses.filter((resp) => resp.address.id !== uuid));
      },
      (error) => {
        console.log(error);
      },
    );
  }

  function setDefaultAddressHandler(event: any) {
    event.preventDefault();
    const uuid = event.target.getAttribute("data-uuid");
    apiClient.setDefaultAddress(uuid).then(() => {
      apiClient.getAddresses().then(
        () => {
          const updatedAddresses = addresses.map((resp) => {
            resp.isDefault = resp.address.id === uuid;
            return resp;
          });
          setAddresses(updatedAddresses);
        },
        (error) => {
          console.log(error);
        },
      );
    });
  }

  useEffect(() => {
    apiClient.getAddresses().then(
      (resp) => {
        setAddresses(resp);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  return (
    <div className="mx-auto max-w-6xl">
      <div className="text-center mb-20">
        <h1 className="text-4xl tracking-tight text-gray-900 sm:text-4xl">
          Vaše adresy
        </h1>
      </div>

      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        <Link to="" onClick={navigateAddAddressHandler}>
          <div
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.addAddress} ${classes.addressItem}`}
          >
            <h2 className="text-gray-800 text-2xl">+</h2>
            <p className="mt-2 text-gray-600">Pridaj Adresu</p>
          </div>
        </Link>

        {addresses.map((resp) => (
          <div
            key={resp.address.id}
            className={`py-4 px-8 bg-white shadow-lg rounded-lg ${classes.addressItem}`}
            style={{ position: "relative" }}
          >
            {resp.isDefault && (
              <div className="text-sm font-bold leading-7 text-gray-600 mb-4">
                Predvolená adresa
              </div>
            )}
            {!resp.isDefault && (
              <div className="text-sm font-bold leading-7 text-gray-600 mb-4">
                {" "}
              </div>
            )}
            <div className="text-sm font-semibold leading-6 text-gray-900">
              {resp.address.fullName}
            </div>
            <div className="text-sm text-gray-600">
              {resp.address.addressLine1}
            </div>
            {resp.address.addressLine2 && resp.address.addressLine2 !== "" && (
              <div className="text-sm text-gray-600">
                {resp.address.addressLine2}
              </div>
            )}
            <div className="text-sm text-gray-600">
              {resp.address.postcode} {resp.address.city}
            </div>
            {resp.address.county && resp.address.county !== "" && (
              <div className="text-sm text-gray-600">{resp.address.county}</div>
            )}
            {resp.address.phoneNumber && resp.address.phoneNumber !== "" && (
              <div className="text-sm text-gray-600">
                Telefón: {resp.address.phoneNumber}
              </div>
            )}

            <div
              className="text-sm text-gray-600 mt-8 text-center"
              style={{ position: "absolute", bottom: "10px" }}
            >
              <Link
                to=""
                onClick={editAddressHandler}
                data-uuid={resp.address.id}
                className="text-blue-800 hover:underline"
              >
                Upraviť
              </Link>
              {"  |  "}
              <Link
                to=""
                onClick={deleteAddressHandler}
                data-uuid={resp.address.id}
                className="text-blue-800 hover:underline"
              >
                Zmazať
              </Link>
              {"  |  "}
              <Link
                to=""
                onClick={setDefaultAddressHandler}
                data-uuid={resp.address.id}
                className="text-blue-800 hover:underline"
              >
                Nastaviť ako predvolenú
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddressManagementPage;
