export default function OtazkyPage() {
  return (
    <section
      id="faqs"
      className="mx-auto w-full max-w-container bg-white mt-6 px-6 py-24 rounded-md sm:py-32
            scroll-m-20 sm:scroll-m-24 xl:scroll-m-32"
    >
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-14 gap-y-16 lg:max-w-none lg:grid-cols-12">
        <div className="lg:col-span-4">
          <h2 className="text-base font-semibold leading-7 text-indigo-500">
            Často Kladené Otázky
          </h2>
          <p className="mt-4 text-4xl font-extrabold tracking-tight text-slate-900 xl:text-5xl xl:leading-[3.5rem]">
            Všetko čo potrebujete vedieť
          </p>
        </div>
        <div className="-mb-4 space-y-12 lg:col-span-8 xl:col-span-7 xl:col-start-6">
          <section>
            <h3 className="text-sm font-semibold leading-7 text-slate-400">
              Všeobecné
            </h3>
            <dl className="mt-2 divide-y divide-slate-100">
              <details className="group py-4 marker:content-['']">
                <summary className="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-slate-900 group-open:text-indigo-600 [&amp;::-webkit-details-marker]:hidden">
                  Je používanie služby Parcium bezplatné?
                  <svg
                    className="ml-4 mt-0.5 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-indigo-500"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 12H6"></path>
                    <path className="group-open:hidden" d="M12 6v12"></path>
                  </svg>
                </summary>
                <div className="pb-6 pt-6">
                  <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                    <p>
                      <strong>Áno</strong>, používanie služby Parcium je úplne
                      bezplatné, takisto ako aj pridávanie komodít a nakupovanie
                      komodít. My žijeme z dobrého pocitu že Vám pomáhame.
                    </p>
                  </div>
                </div>
              </details>
            </dl>
          </section>
          <section>
            <h3 className="text-sm font-semibold leading-7 text-slate-400">
              Nakupovanie
            </h3>
            <dl className="mt-2 divide-y divide-slate-100">
              <details className="group py-4 marker:content-['']">
                <summary className="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-slate-900 group-open:text-indigo-600 [&amp;::-webkit-details-marker]:hidden">
                  Chcel by som nakúpiť komodity, čo mám robiť?
                  <svg
                    className="ml-4 mt-0.5 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-indigo-500"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 12H6"></path>
                    <path className="group-open:hidden" d="M12 6v12"></path>
                  </svg>
                </summary>
                <div className="pb-6 pt-6">
                  <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                    <p>
                      Ideálne je ak si popozeráte komodity ktoré su práve na
                      našom marketplace a vyberiete si o ktorí máte záujem.
                      Potom si ho pridajte do košíka a vyberte si či máte záujem
                      aj o transport. Ked sa objednávka skompletizuje, tak ju
                      zaplaťte a komodity Vám budú doručené na Vami udanú adresu
                      v požadovanom termíne.
                    </p>
                  </div>
                </div>
              </details>
              <details className="group py-4 marker:content-['']">
                <summary className="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-slate-900 group-open:text-indigo-600 [&amp;::-webkit-details-marker]:hidden">
                  Bol som podvedený, ako postupovať?
                  <svg
                    className="ml-4 mt-0.5 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-indigo-500"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 12H6"></path>
                    <path className="group-open:hidden" d="M12 6v12"></path>
                  </svg>
                </summary>
                <div className="pb-6 pt-6">
                  <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                    <p>
                      Podajte trestné oznámenie. Môžete ho podať ústne alebo
                      písomne na ktoromkoľvek oddelení Policajného zboru alebo
                      prokuratúre. Tieto orgány sú povinné oznámenie prijať a
                      bez meškania vec vyšetriť. V trestnom oznámení popíšte, čo
                      sa presne stalo (kedy, kde a kto sa trestného činu
                      dopustil). Priložte kópiu inzerátu, vytlačenú e-mailovú
                      korešpondenciu a doklad o zaplatení. Nezabudnite udať, že
                      chcete byť informovaní o vykonaných opatreniach. Na
                      stránkach Parcium napíšte predajcovi negatívne hodnotenie,
                      kde popíšete čo presne sa Vám stalo.
                    </p>
                  </div>
                </div>
              </details>
            </dl>
          </section>
          <section>
            <h3 className="text-sm font-semibold leading-7 text-slate-400">
              Predávanie
            </h3>
            <dl className="mt-2 divide-y divide-slate-100">
              <details className="group py-4 marker:content-['']">
                <summary className="flex w-full cursor-pointer select-none justify-between text-left text-base font-semibold leading-7 text-slate-900 group-open:text-indigo-600 [&amp;::-webkit-details-marker]:hidden">
                  Musím sa pred vložením komodity prihlásiť, resp.
                  zaregistrovať?
                  <svg
                    className="ml-4 mt-0.5 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-indigo-500"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 12H6"></path>
                    <path className="group-open:hidden" d="M12 6v12"></path>
                  </svg>
                </summary>
                <div className="pb-6 pt-6">
                  <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                    <p>
                      Ano, je to nutné. Registrácia je jednoduchá a rýchla. Po
                      registrácii sa môžete prihlásiť a vytvoriť si Parcium
                      Business v rámci ktorého potom môžte pridať komoditu ktorú
                      chcete predávať.
                    </p>
                  </div>
                </div>
              </details>
            </dl>
          </section>
          {/*<section><h3 className="text-sm font-semibold leading-7 text-slate-400">Podpora</h3>*/}
          {/*    <dl className="mt-2 divide-y divide-slate-100">*/}

          {/*    </dl>*/}
          {/*</section>*/}
        </div>
      </div>
    </section>
  );
}
