import {
  AddBasketItemRequest,
  Address,
  AddressResponse,
  Basket,
  BasketItem,
  BasketsService,
  BooleanOk,
  Business,
  BusinessService,
  BusinessWithStockAndDistanceDTO,
  CancelablePromise,
  CategoryService,
  CheckoutService,
  Commodity,
  CommodityService,
  CreateStockRequest,
  IdentityResult,
  IdentityService,
  MasterOrderDTO,
  Ok,
  OkResult,
  OpenAPI,
  RegisterRequest,
  SignInRequest,
  SignInResponse,
  Stock,
  StockDTO,
  UpdateAddressRequest,
  UpdateBusiness,
  UpdatePasswordRequest,
  UpdateStockRequest,
  UserDashboard,
  UsersService,
} from "../tapi";

interface IApiClient {
  BASE_URL: string;
  BASE_HEADERS: Object;

  login(request: SignInRequest): CancelablePromise<SignInResponse>;
  getDashboardInfo(): CancelablePromise<UserDashboard>;
  getBusiness(businessUuid: string): CancelablePromise<Business>;
  addStock(
    businessId: string,
    stockRequest: CreateStockRequest,
  ): CancelablePromise<StockDTO>;
  updateStock(
    businessId: string,
    stockId: string,
    requestBody: UpdateStockRequest,
  ): CancelablePromise<OkResult>;
  getStockOfBusiness(businessId: string): CancelablePromise<StockDTO[]>;
  getStockByStockId(stockId: string): CancelablePromise<StockDTO>;
  deleteStock(id: string): CancelablePromise<OkResult>;
  listCategories(subCategories?: boolean): CancelablePromise<any>;
  getCategory(
    categoryId: string,
    subCategories?: boolean,
  ): CancelablePromise<any>;
  listSubCategories(categoryId: string): CancelablePromise<any>;
  getSubCategoryById(
    subCategoryId: string,
    filter?: boolean,
  ): CancelablePromise<any>;
  listFiltersBySubCategory(subCategoryId: string): CancelablePromise<any>;
  getCommoditiesBySubcategory(subCategoryId: string): CancelablePromise<any>;
  getCommodities(
    categoryId?: string,
    subCategoryId?: string,
  ): CancelablePromise<Commodity[]>;
  getCommodity(commodityId: string): CancelablePromise<Commodity>;
  getAddressesMe(): CancelablePromise<AddressResponse[]>;
  addAddress(newAddress: Address): CancelablePromise<Address>;
  updateAddress(
    addressId: string,
    updateAddressRequest: UpdateAddressRequest,
  ): CancelablePromise<Address>;
  getAddresses(): CancelablePromise<any>;
  deleteAddress(id: string): CancelablePromise<OkResult>;
  setDefaultAddress(id: string): CancelablePromise<OkResult>;
  sendPhoneNumberUpdate(phoneNumber: string): CancelablePromise<BooleanOk>;
  sendPasswordUpdate(rq: UpdatePasswordRequest): CancelablePromise<Ok>;
  sendBusinessUpdate(
    businessUuid: string,
    updateBusiness: UpdateBusiness,
  ): CancelablePromise<any>;
  getBusinessMe(): CancelablePromise<Business[]>;
  register(registerUser: RegisterRequest): CancelablePromise<IdentityResult>;
  getBasket(basketId: string): CancelablePromise<Basket>;
  emptyBasket(basketId: string): CancelablePromise<void>;
  addItemToBasket(
    basketRequest: AddBasketItemRequest,
  ): CancelablePromise<boolean>;
  removeItemFromBasket(basketItemId: string): CancelablePromise<boolean>;
  updateItemInBasket(
    basketItemId: string,
    amount: number,
  ): CancelablePromise<BasketItem>;
  startCheckout(): CancelablePromise<MasterOrderDTO>;
}

class ApiClient implements IApiClient {
  BASE_URL = "https://tcore.azurewebsites.net";
  BASE_HEADERS = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  setOpenApiHeaders(auth: boolean) {
    OpenAPI.BASE = this.BASE_URL;
    OpenAPI.HEADERS = this.BASE_HEADERS;
    if (auth) {
      const token = localStorage.getItem("token");
      if (token === null) {
        throw new Error("User is not logged in");
      }
      OpenAPI.TOKEN = token;
    }
  }

  // Authentication
  public register(
    registerUser: RegisterRequest,
  ): CancelablePromise<IdentityResult> {
    this.setOpenApiHeaders(false);
    return IdentityService.postIdentityAuthenticationRegister(registerUser);
  }
  public login(request: SignInRequest): CancelablePromise<SignInResponse> {
    this.setOpenApiHeaders(false);
    return IdentityService.postIdentityAuthenticationSignIn(request);
  }

  public getDashboardInfo(): CancelablePromise<UserDashboard> {
    this.setOpenApiHeaders(true);
    return UsersService.getUsersMeMyDashboard();
  }

  // User
  public sendPasswordUpdate(rq: UpdatePasswordRequest): CancelablePromise<Ok> {
    this.setOpenApiHeaders(true);
    return UsersService.postUsersMeUpdatePassword(rq);
  }
  public sendPhoneNumberUpdate(
    phoneNumber: string,
  ): CancelablePromise<BooleanOk> {
    this.setOpenApiHeaders(true);
    return UsersService.postUsersMeUpdatePhoneNumber({
      phoneNumber: phoneNumber,
    });
  }

  // Address
  public getAddressesMe(): CancelablePromise<AddressResponse[]> {
    this.setOpenApiHeaders(true);
    return UsersService.getUsersMeAddresses();
  }
  public addAddress(newAddress: Address): CancelablePromise<Address> {
    this.setOpenApiHeaders(true);
    return IdentityService.postIdentityAddresses(newAddress);
  }
  public updateAddress(
    addressId: string,
    updateAddressRequest: UpdateAddressRequest,
  ): CancelablePromise<Address> {
    this.setOpenApiHeaders(true);
    return IdentityService.putIdentityAddresses(
      addressId,
      updateAddressRequest,
    );
  }
  public getAddresses(): CancelablePromise<AddressResponse[]> {
    this.setOpenApiHeaders(true);
    return UsersService.getUsersMeAddresses();
  }
  public deleteAddress(id: string): CancelablePromise<OkResult> {
    this.setOpenApiHeaders(true);
    return UsersService.deleteUsersMeAddresses(id);
  }
  public setDefaultAddress(id: string): CancelablePromise<OkResult> {
    this.setOpenApiHeaders(true);
    return UsersService.patchUsersMeAddressesDefault(id);
  }

  // Business
  public getBusiness(businessUuid: string): CancelablePromise<Business> {
    this.setOpenApiHeaders(true);
    return BusinessService.getBusiness1(businessUuid);
  }
  public sendBusinessUpdate(
    businessUuid: string,
    updateBusiness: UpdateBusiness,
  ) {
    this.setOpenApiHeaders(true);
    return BusinessService.postBusiness(businessUuid, updateBusiness);
  }
  public getBusinessMe() {
    this.setOpenApiHeaders(true);
    return UsersService.getUsersMeBusiness();
  }

  public getParciumFindNearby(
    longitude?: number,
    latitude?: number,
    maxResults: number = 10,
    categoryId?: string,
    commodityId?: string,
  ): CancelablePromise<Array<BusinessWithStockAndDistanceDTO>> {
    this.setOpenApiHeaders(false);
    return new CancelablePromise<Array<BusinessWithStockAndDistanceDTO>>(
      (resolve, reject) => {
        fetch(
          `${this.BASE_URL}/Parcium/FindNearby?latitude=${latitude}&longitude=${longitude}&maxResults=${maxResults}${categoryId ? `&categoryId=${categoryId}` : ""}${commodityId ? `&commodityId=${commodityId}` : ""}`,
          {
            method: "GET",
            headers: {
              ...this.BASE_HEADERS,
              Authorization: `Bearer ${OpenAPI.TOKEN}`,
            },
          },
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      },
    );
  }

  // stock
  public addStock(
    businessId: string,
    stockRequest: CreateStockRequest,
  ): CancelablePromise<StockDTO> {
    this.setOpenApiHeaders(true);
    return BusinessService.postBusinessStock(businessId, stockRequest);
  }
  public updateStock(
    businessId: string,
    stockId: string,
    requestBody: UpdateStockRequest,
  ): CancelablePromise<OkResult> {
    this.setOpenApiHeaders(true);
    return BusinessService.putBusinessStock(businessId, stockId, requestBody);
  }
  public getStock(): CancelablePromise<Stock[]> {
    this.setOpenApiHeaders(false);
    // @ts-ignore
    return BusinessService.getBusinessStock();
  }
  public getStockOfBusiness(businessId: string): CancelablePromise<StockDTO[]> {
    this.setOpenApiHeaders(false);
    return BusinessService.getBusinessStock2(businessId);
  }
  public getStockByStockId(stockId: string): CancelablePromise<StockDTO> {
    this.setOpenApiHeaders(false);
    return BusinessService.getBusinessStock1(stockId);
  }
  public deleteStock(id: string): CancelablePromise<OkResult> {
    this.setOpenApiHeaders(true);
    return BusinessService.deleteBusinessStock(id);
  }

  // Category
  public listCategories(subCategories?: boolean) {
    this.setOpenApiHeaders(false);
    return CategoryService.getCategory(subCategories);
  }
  public getCategory(categoryId: string, subCategories?: boolean) {
    this.setOpenApiHeaders(false);
    return CategoryService.getCategory1(categoryId, subCategories);
  }
  public listSubCategories(categoryId: string) {
    this.setOpenApiHeaders(false);
    return CategoryService.getCategorySubcategory(categoryId);
  }
  public getSubCategoryById(subCategoryId: string, filter?: boolean) {
    this.setOpenApiHeaders(false);
    return CategoryService.getCategorySubcategory1(subCategoryId, filter);
  }
  public listFiltersBySubCategory(subCategoryId: string) {
    this.setOpenApiHeaders(false);
    return CategoryService.getCategorySubcategoryFilters(subCategoryId);
  }
  public getCommoditiesBySubcategory(subCategoryId: string) {
    this.setOpenApiHeaders(false);
    return CategoryService.getCategorySubcategoryCommodity(subCategoryId);
  }

  // Commodity
  public getCommodities(categoryId?: string, subCategoryId?: string) {
    this.setOpenApiHeaders(false);
    return CommodityService.getCommodity1(categoryId, subCategoryId);
  }
  public getCommodity(commodityId: string) {
    this.setOpenApiHeaders(false);
    return CommodityService.getCommodity(commodityId);
  }

  // Basket
  public getBasket(basketId: string): CancelablePromise<Basket> {
    this.setOpenApiHeaders(true);
    return BasketsService.getBaskets(basketId);
  }
  public emptyBasket(basketId: string): CancelablePromise<void> {
    this.setOpenApiHeaders(true);
    return BasketsService.deleteBasketsItems1(basketId);
  }
  public addItemToBasket(
    basketRequest: AddBasketItemRequest,
  ): CancelablePromise<boolean> {
    this.setOpenApiHeaders(true);
    return BasketsService.postBasketsItems(basketRequest);
  }
  public removeItemFromBasket(
    basketItemId: string,
  ): CancelablePromise<boolean> {
    this.setOpenApiHeaders(true);
    return BasketsService.deleteBasketsItems(basketItemId);
  }
  public updateItemInBasket(
    basketItemId: string,
    amount: number,
  ): CancelablePromise<BasketItem> {
    this.setOpenApiHeaders(true);
    return BasketsService.patchBasketsItems(basketItemId, amount);
  }

  // Checkout
  public startCheckout(): CancelablePromise<MasterOrderDTO> {
    this.setOpenApiHeaders(true);
    return CheckoutService.postCheckoutOrders();
  }
}

export default ApiClient;
