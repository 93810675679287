import { formatPrice } from "@/helpers/formatPrice.ts";

export const formatDistance = (meters: number | null | undefined): string => {
  if (!meters) return "0 km";
  return `${(meters / 1000).toFixed(2)} km`;
};

export const calculateTransportPrice = (
  pricePerKm: number | null | undefined,
  distanceInMeters: number | null | undefined,
): string => {
  if (!pricePerKm || !distanceInMeters) return "Cena dopravy neuvedena";
  const distanceInKm = distanceInMeters / 1000;
  const totalPrice = pricePerKm * distanceInKm;
  return `${pricePerKm} Kč/km (${formatPrice(String(totalPrice))} celkem)`;
};
