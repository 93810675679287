import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/20/solid";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "../components/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { Button } from "../components/ui/button";
import { Menu } from "lucide-react";

interface NavigationItem {
  name: string;
  to: string;
}

interface NavLinkWithCloseProps {
  to: string;
  children: React.ReactNode;
  className: (props: { isActive: boolean }) => string;
}

const navigation: NavigationItem[] = [
  { name: "Srovnávač", to: "/business/findNearby" },
  { name: "Marketplace", to: "/komodity" },
  { name: "Nápověda", to: "/otazky" },
  { name: "O společnosti", to: "/o-nas" },
];

function MainNavigation(): JSX.Element {
  const navigate = useNavigate();
  const [token] = useState<string | null>(localStorage.getItem("token"));
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (tokenExpiration !== null) {
      if (new Date() > new Date(tokenExpiration)) {
        navigate("/logout");
      }
    }
  }, [navigate]);

  const handleNavClick = (): void => {
    setMobileMenuOpen(false);
  };

  const NavLinkWithClose: React.FC<NavLinkWithCloseProps> = ({
    to,
    children,
    className,
  }) => (
    <NavLink to={to} className={className} onClick={handleNavClick}>
      {children}
    </NavLink>
  );

  return (
    <header className="sticky top-0 w-full bg-primary text-primary-foreground z-50">
      <div className="container flex h-16 items-center">
        <NavLink to="/" className="mr-8">
          <span className="text-2xl tracking-wider font-bold">PARCIUM</span>
        </NavLink>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex">
          <NavigationMenu>
            <NavigationMenuList>
              {navigation.map((item) => (
                <NavigationMenuItem key={item.name}>
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      `px-4 py-2 text-base text-white hover:text-white/80 transition-colors ${
                        isActive ? "underline underline-offset-4" : ""
                      }`
                    }
                  >
                    {item.name}
                  </NavLink>
                </NavigationMenuItem>
              ))}
            </NavigationMenuList>
          </NavigationMenu>
        </div>

        {/* Right side menu (desktop) */}
        <div className="hidden lg:flex ml-auto">
          {token ? (
            <div className="flex items-center gap-4 text-white">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `flex items-center gap-2 text-base hover:text-white/80 transition-colors ${
                    isActive ? "underline underline-offset-4" : ""
                  }`
                }
              >
                <HomeIcon className="h-4 w-4" /> Dashboard
              </NavLink>
              <NavLink
                to="/logout"
                className={({ isActive }) =>
                  `flex items-center gap-2 text-base hover:text-white/80 transition-colors ${
                    isActive ? "underline underline-offset-4" : ""
                  }`
                }
              >
                Odhlásiť sa
              </NavLink>
            </div>
          ) : (
            <NavLink
              to="/login"
              className={({ isActive }) =>
                `flex items-center gap-2 text-base text-white hover:text-white/80 transition-colors ${
                  isActive ? "underline underline-offset-4" : ""
                }`
              }
            >
              Prihlásiť sa
            </NavLink>
          )}
        </div>

        {/* Mobile Menu */}
        <div className="flex lg:hidden ml-auto">
          <Sheet open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="text-white hover:text-white/80"
              >
                <Menu className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Menu</SheetTitle>
              </SheetHeader>
              <div className="flex flex-col gap-4 mt-4">
                {navigation.map((item) => (
                  <NavLinkWithClose
                    key={item.name}
                    to={item.to}
                    className={({ isActive }) =>
                      `text-lg hover:text-primary transition-colors ${
                        isActive
                          ? "text-primary underline underline-offset-4"
                          : ""
                      }`
                    }
                  >
                    {item.name}
                  </NavLinkWithClose>
                ))}
                <div className="h-px bg-border my-4" />
                {token ? (
                  <>
                    <NavLinkWithClose
                      to="/dashboard"
                      className={({ isActive }) =>
                        `flex items-center gap-2 text-lg hover:text-primary transition-colors ${
                          isActive
                            ? "text-primary underline underline-offset-4"
                            : ""
                        }`
                      }
                    >
                      <HomeIcon className="h-4 w-4" /> Dashboard
                    </NavLinkWithClose>
                    <NavLinkWithClose
                      to="/logout"
                      className={({ isActive }) =>
                        `flex items-center gap-2 text-lg hover:text-primary transition-colors ${
                          isActive
                            ? "text-primary underline underline-offset-4"
                            : ""
                        }`
                      }
                    >
                      Odhlásiť sa
                    </NavLinkWithClose>
                  </>
                ) : (
                  <NavLinkWithClose
                    to="/login"
                    className={({ isActive }) =>
                      `flex items-center gap-2 text-lg hover:text-primary transition-colors ${
                        isActive
                          ? "text-primary underline underline-offset-4"
                          : ""
                      }`
                    }
                  >
                    Prihlásiť sa
                  </NavLinkWithClose>
                )}
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </header>
  );
}

export default MainNavigation;
