import { FC } from "react";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Category, Commodity } from "@/tapi";

interface SearchBarProps {
  searchType: "category" | "commodity";
  onSearchTypeChange: (checked: boolean) => void;
  selectedItem: Category | Commodity | null;
  categories: Category[];
  commodities: Commodity[];
  onItemSelect: (itemId: string) => void;
  zipCode: string;
  onZipCodeChange: (value: string) => void;
  onSearch: () => Promise<void>;
  loading: boolean;
  isSearching: boolean;
}

export const SearchBar: FC<SearchBarProps> = ({
  searchType,
  onSearchTypeChange,
  selectedItem,
  categories,
  commodities,
  onItemSelect,
  zipCode,
  onZipCodeChange,
  onSearch,
  loading,
  isSearching,
}) => {
  return (
      <div
          className={`flex flex-wrap gap-4 items-center bg-white rounded-lg border-4 border-accent px-5 py-4 
              transition-all duration-500 ease-in-out w-full
              shadow-xl]
              ${
              isSearching
                  ? "mb-8 shadow-2xl"
                  : "md:w-9/12 md:absolute md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 shadow-2xl"
          }
              ${loading ? "opacity-70" : "opacity-100"}
              ${!isSearching ? "mt-0" : "mt-2 md:mt-8"}
          `}
      >
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium">Kategorie</span>
          <Switch
              checked={searchType === "commodity"}
              onCheckedChange={onSearchTypeChange}
          />
          <span className="text-sm font-medium">Komodita</span>
        </div>

        <div className="flex-1 min-w-[200px]">
          <Select
              key={searchType}
              onValueChange={onItemSelect}
              value={selectedItem?.id || ""}
          >
            <SelectTrigger>
              <SelectValue
                  placeholder={`Vyberte ${searchType === "category" ? "kategorii" : "komoditu"}...`}
              />
            </SelectTrigger>
            <SelectContent>
              {(searchType === "category" ? categories : commodities).map(
                  (item) => (
                      <SelectItem key={item.id} value={item.id ?? ""}>
                        {item.name}
                      </SelectItem>
                  ),
              )}
            </SelectContent>
          </Select>
        </div>

        <input
            type="text"
            value={zipCode}
            onChange={(e) => onZipCodeChange(e.target.value)}
            placeholder="PSČ"
            className="px-3 py-2 border rounded-md w-24 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        />

        <button
            onClick={onSearch}
            disabled={loading || !selectedItem || !zipCode}
            className="btn-primary disabled:opacity-50 disabled:cursor-not-allowed min-w-[100px]"
        >
          {loading ? "Hledám..." : "Hledat"}
        </button>
      </div>
  );
};
