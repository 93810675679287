import { FC } from "react";

interface BusinessActionsProps {
  isPartner: boolean;
  businessId: string | null | undefined;
}

export const BusinessActions: FC<BusinessActionsProps> = ({
  isPartner,
  businessId,
}) => (
  <div className="mt-6 text-center">
    {isPartner ? (
      <button className="btn-primary w-full">Koupit</button>
    ) : (
      <a
        href={`https://${businessId}`}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-secondary w-full text-center text-center"
      >
        Přejít na web
      </a>
    )}
  </div>
);
